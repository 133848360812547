/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Translation file for Indonesia language
 *
 * @file id.js
 * @author Manimaran.S
 */

const id = {
  'popularChannelSlider.allChannels': 'Semua Kanal',
  'popularChannelSlider.recommended': 'Direkomendasikan',
  'channel.noBundleRelatedtitle': 'Telusuri Belajar & Persiapan Ujian yang terkait kelas Anda lainnya.',
  'hero.myetextbook.link': 'perpustakaan',
  'hero.myetextbook.text': 'Bukan judul yang tepat? Akses buku eText Anda',
  'hero.ctanewtext': 'Open Study & Exam Prep',
  'hero.welcomeToPPlus': 'Selamat datang di Pearson+',
  'hero.etextfeaturestitle': 'Temukan materi belajar di buku eText Anda',
  'hero.etextfeaturesdescription': 'Buku eText Anda disertai dengan materi belajar yang dirancang untuk membantu Anda berhasil. Belajar dengan flashcard dan lainnya tepat di buku eText Anda.',
  'aichat.title1': 'Dapatkan bantuan berbasis AI untuk apa saja terkait buku eText Anda.',
  'aichat.chatDesc1': 'Tulis pertanyaan untuk memulai',
  'aichat.title2': 'Dapatkan bantuan berbasis AI',
  'aichat.chatDesc2': 'Tanyakan tentang apa saja terkait buku eText Anda',
  'subscriptiontext.isSubscribed': 'Bagian langganan Anda',
  'subscriptiontext.nonSubscriber': 'Tingkatkan untuk akses penuh',
  'topicConatiner.otherHeader': 'Related video lessons & practice',
  'topicConatiner.exactLiteHeader': 'Latihan terkait',
  'common.LOGOUT': 'Keluar',
  'common.GO_HOME': 'Buka Beranda',
  'common.PURCHASE': 'Pembelian',
  'courses.WELCOME': 'Halo {userName}, selamat datang di kursus {courseName}!',
  'courses.action.SHOW_ASSIGNMENTS': 'Tampilkan Tugas',
  'courses.toc.PUBLISH_SUCCESS': 'Berhasil!',
  'courses.toc.PUBLISH_MESSAGE': 'Siswa hanya akan melihat perubahan Anda saat mereka membuka konten nanti.',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'Langganan Tidak Ditemukan',
  'errors.SUBSCRIPTION_GENERIC_DESC': `Akun Anda tidak mencakup akses ke judul ini. Jika Anda pikir Anda menerima pesan ini karena kesalahan,
      silakan hubungi Dukungan Teknis Pearson`,
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'Langganan Tidak Ditemukan',
  'errors.SUBSCRIPTION_REGISTRATION_DESC': `Langganan Anda tidak mencakup akses ke eText, akan tetapi Anda dapat berlangganan eText sekarang 
    menggunakan kartu kredit.`,
  'foxit.ABOUT': 'Tentang',
  'foxit.COPY_RIGHT': 'PDF Reader Didukung oleh Foxit. Hak Cipta (C) 2003-2019 oleh Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonPrivacyPolicy.html',
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonEULA.html',
  'menu.GET_THE_APP_DOWNLOAD': 'Unduh aplikasi seluler',
  'menu.CONTACT_SUPPORT': 'Hubungi Dukungan',
  'menu.ABOUT': 'Tentang',
  'menu.FOXIT': 'Didukung oleh Foxit',
  'menu.TERMS_OF_USE': 'Ketentuan Penggunaan',
  'menu.PRIVACY_POLICY': 'Kebijakan Privasi',
  'menu.SIGN_OUT': 'Keluar',
  'menu.PRINT_PAGE': 'Cetak halaman',
  'menu.GET_LOOSE_LEAF': 'Dapatkan halaman lepas',
  'menu.AVAILABLE': 'Juga Tersedia',
  'menu.PRINT_VERSION': 'Ingin versi cetak?',
  'menu.COPY_RIGHT': 'Hak Cipta © {year} Pearson Education Inc. Hak Cipta Dilindungi Undang-Undang.',
  'menu.tooltip.SCHEDULE': 'Jadwal',
  'print.WATERMARK': 'Hak Cipta © {year} Pearson Education',
  'product.WELCOME': 'Halo {userName}',
  'device.SWAPPED_ALREADY': 'Anda sudah mengganti bulan ini',
  'device.SWITCH_DEVICE': 'Beralih ke perangkat saat ini?',
  'device.SWITCH_ONCE': 'Karena Anda hanya dapat berganti sekali dalam sebulan, Anda tidak akan dapat mengurungkan ini.',
  'device.SEAT_TAKEN': 'Kursi tersebut sudah terisi',
  'device.DISCONNECT_DEVICE': 'Anda hanya dapat masuk di 3 perangkat. Untuk melanjutkan di sini, putuskan perangkat lainnya.',
  'device.ALERT_TEXT': 'Ingat, Anda hanya dapat berganti perangkat sekali dalam sebulan.',
  'device.YOUR_MOJO': 'Sedikit lagi Anda sampai ke Mojo',
  'device.DISCONNECT': 'Putuskan',
  'device.CONTINUE': 'Lanjutkan',
  'device.HEADER': 'End your other browser session?',
  'device.CLOSE': 'Kembali ke beranda',
  'device.CODE': 'Continue here by entering the authentication code we sent to {maskedEmail}. Not working? Check your spam folder, {email}, or contact {support}.',
  'device.TIMER': 'Anda punya {time} untuk memasukkan kode di sini',
  'device.RESEND': '{resend}',
  'device.FAQ': 'FAQ',
  'device.RESEND_NAME': 'Resend',
  'device.NOTE': 'If you regularly use Pearson+ in 2 places, like at work and at home, make sure to log out whenever you’re done. You can also use the Pearson+ mobile app on up to 2 devices. For more info, see our {faq}.',
  'device.NOTE_HEADER': 'Avoid this next time',
  'device.CONTACT_SUPPORT': '',
  'device.CONTACT_EMAIL': '',
  'device.CONTACT_FAQ': '',
  'device.INCORRECT': 'Salah—periksa kembali kode Anda atau kirim ulang.',
  'device.MANY_ATTEMPTS': 'Terlalu banyak percobaan, coba kirim ulang kode',
  'device.EXPIRED': 'Waktu Anda habis, coba kirim ulang kode',
  'device.ACCESS_HEADER': 'Anda sudah masuk!',
  'device.ACCESS_DESC': 'Sesi Anda telah diautentikasi. Ingin memperbarui kata sandi saat Anda berada di sesi ini?',
  'device.ACCESS_CHANGE_PASSWORD': 'Ubah kata sandi',
  'device.ACCESS_RESET_PASSWORD': 'Atur ulang kata sandi',
  'device.THREATONE_TITLE': 'Semuanya baik-baik saja?',
  'device.THREATONE_DESC': 'Kami memperhatikan beberapa aktivitas mencurigakan di akun Anda.',
  'device.THREATONE_DESC_TWO': 'Ini waktu yang tepat untuk mengubah kata sandi Anda.',
  'device.MAY_BE_LATER': 'Mungkin nanti',
  'device.OKAY': 'Oke',
  'device.ACCOUNT_LOCK_DESC': 'Untuk mengamankannya, kami telah mengeluarkan Anda. Kami telah mengirim tautan pengaturan ulang kata sandi ke email yang terkait dengan akun Anda.',
  'device.ACCOUNT_LOCK_TITLE': 'Kami melihat aktivitas mencurigakan di akun Anda',
  'device.ACCOUNT_LOCK_TIMER': 'Anda akan dikeluarkan dalam {time}',
  'menu.myAccount': 'Akun saya',
  'menu.helpCenter': 'Pusat Bantuan',
  'menu.preferencesCenter': 'Pusat Preferensi',
  'menu.HELP_SUPPORT': 'Bantuan & dukungan',
  'menu.FAQS': 'Tanya-Jawab',
  'menu.CHAT_WITH_SUPPORT': 'Bicara dengan tim dukungan',
  'menu.QUICK_WALKTHROUGH': 'Panduan cepat',
  'menu.LEGAL': 'Legal',
  'menu.PRIVACY_NOTICE': 'Pemberitahuan Privasi',
  'menu.TITLE_INFO': 'Info Judul',
  'menu.PEARSON_ACCESSIBILITY': 'Aksesibilitas Pearson',
  'menu.DEVICE_WARNING_MESSAGE': 'Daftarkan {deviceLimit} perangkat. Ganti 1 dalam sebulan.',
  'menu.ACCESSIBILITY': 'Aksesibilitas',
  'menu.ACCESSIBILITY_SUPPORT': 'Dukungan aksesibilitas',
  'menu.DEVICES': 'Perangkat terbaru',
  'menu.THIS_DEVICE': 'Perangkat ini',
  'menu.MOJO_APP_INFO': 'Kunjungi app store di ponsel atau tablet Anda untuk menggunakan Pearson+ secara offline.',
  'menu.DEVICE_LABEL': 'Perangkat',
  'navigation.PAGE': 'Halaman',
  'launchcode.CREATEBY': 'Dibuat oleh:',
  'launchcode.sentvia': 'Dikirim via:',
  'launchcode.CODEINLAST': 'Kode dalam 7 hari terakhir:',
  'launchcode.TIMESENT': 'Waktu dikirim:',
  'launchcode.ACCOUNTEMAIL': 'Email akun:',
  'launchcode.LAUNCHDIALOGTITLE': 'Log Kode OTP',
  'menu.OTP_CODES': 'Kode OTP',
  'menu.LAUNCH_CODE': 'Luncurkan log kode',
  'menu.SEND_CODE': 'Kirim kode baru ke pengguna via email',
  'menu.SEND_CODE_PHONE': 'Kirim kode baru ke pengguna via SMS',
  'menu.COMMUNITY_GUIDELINES': 'Panduan komunitas',
  'textmessage.TITLE': 'Masukkan Nomor Telepon',
  'textmessage.PHONE_NUMBER': 'Nomor Telepon',
  'textmessage.SEND_CODE': 'Kirim Kode',
  'textmessage.INVALID_NUMBER': 'Nomor Telepon Tidak Valid.',
  'textmessage.SUCCESS_MESSAGE': 'Kode terkirim',
  'search.searchPlaceHolderPPlus': 'Search by ISBN, Title, or Author',
  'search.searchPlaceHolderEText': 'Cari daftar Anda',
  'remove.title': 'Hapus judul?',
  'remove.subTitle': 'Anda selalu bisa mendapatkannya kembali nanti—cukup gunakan pencarian untuk menambahkannya kembali.',
  'remove.buttonText': 'Hapus',
  'footer.downloadMobileApp': 'Unduh aplikasi Seluler',
  'footer.termsOfUse': 'Ketentuan penggunaan',
  'footer.privacy': 'Privasi',
  'footer.cookies': 'Cookie',
  'footer.doNotSellMyPersonalInformation': 'Jangan jual informasi pribadi saya',
  'footer.accessibility': 'Aksesibilitas',
  'footer.patentNotice': 'Pemberitahuan paten',
  'footer.copyrights': 'Pearson Hak cipta dilindungi undang-undang.',
  'channel.videos': 'Video',
  'channel.questions': 'Pertanyaan',
  'channel.descriptionA': 'Lengkapi bacaan Anda dengan Kanal.',
  'channel.descriptionB': 'Dapatkan bantuan tentang topik yang sulit dengan Kanal.',
  'channel.openchannel': 'Buka kanal',
  'hero.continueReading': 'Lanjutkan membaca',
  'hero.openBook': 'Buka buku',
  'hero.channel.open': 'Buka Kanal',
  'hero.addATitle': 'Tambahkan judul',
  'hero.toc.defaultPlacehoder': '--- select a topic ---',
  'hero.viewInactiveTitles': 'Lihat judul nonaktif',
  'library.marketingCard': {
    title: 'Choose from over 2,000 eTextbooks',
    subTitle: 'with audio, flashcards, notes, and more.',
    primaryButton: {
      text: 'Temukan Buku eText Anda'
    },
    secondaryButton: {
      text: 'View all titles'
    }
  },
  'hero.mappedChannelV2': {
    ariaLabel: 'channel',
    heading: 'Recommended study help Channel',
    callForAction: {
      text: 'Open'
    }
  },
  'hero.openEtextBook': 'Buka Buku eText',
  'hero.openEtextBookV1': 'Buka Buku eText',
  'hero.heroHeading': 'Karena Anda belajar <span>{channel_title}</span>',
  'hero.heroHeadingForBundleUser': 'Study & Exam Prep',
  'hero.study.help': 'Dapatkan bantuan belajar',
  'hero.study.jumpBackIn': 'Lanjut kembali',
  'hero.study.numOfUsers': 'Saat ini ada <b>{num_of_users}</b> pelajar yang menggunakan Kanal.',
  'hero.heroBook.sectionHeading': 'Hindari kebingungan dalam kursus Anda',
  'hero.heroBook.channelsSectionHeading': 'Mulai sesi belajar yang baru',
  'hero.heroBook.sectionHeadingNewUser': 'Bantuan belajar ada di sini',
  'hero.heroBook.sectionSubHeading': 'Pecahkan topik sulit dengan pertanyaan latihan dan penjelasan video yang disesuaikan dengan kursus Anda.',
  'hero.heroChannel.cardsHeading': 'Lanjutkan dari tempat terakhir Anda keluar',
  'hero.marketingcard.subheading': 'Belajarlah lebih efisien dengan video yang menguraikan konsep sulit dan latihan masalah, semuanya disesuaikan dengan kursus Anda.',
  'hero.marketingcard1.copy.title': 'Belajar dengan video',
  'hero.marketingcard1.copy.description': 'Tonton penjelasan video singkat yang menguraikan topik-topik sulit.',
  'hero.marketingcard2.copy.title': 'Berlatih masalah',
  'hero.marketingcard2.copy.description': 'Persiapkan diri untuk ujian dengan ribuan pertanyaan seperti ujian dan solusi video',
  'hero.marketingcard3.copy.title': 'Dapatkan bantuan berpengalaman',
  'hero.marketingcard3.copy.description': 'Posting pertanyaan dan dapakan jawaban dari pakar bidang studi.',
  'hero.popularTopics.heading': 'Topik populer lainnya di Kanal ini.',
  'hero.popularChannels.heading': 'Kanal Populer',
  'hero.popularChannels.headingv1': 'Mengikuti kursus lainnya?',
  'hero.popularChannels.description': 'Dapatkan bantuan belajar dengan Kanal teratas ini.',
  'hero.popularChannels.descriptionv1': 'Dapatkan bantuan di kursus lain dengan memilih kanal.',
  'hero.popularChannels.descriptionv2': 'Jejalahi topik bantuan belajar yang populer.',
  'hero.startReading': 'Mulai membaca',
  'hero.practice': 'Latihan',
  'hero.noTitleNoChannel.sectionHeading': 'Persiapkan diri untuk ujian dengan cepat',
  'hero.herobook.mappedChannelHeading': 'Bantuan belajar yang direkomendasikan dalam Kanal ini',
  'libraryL2.myBooks': 'My Books',
  'libraryL2.archived': 'Archived',
  'libraryL2.back': 'Kembali',
  'hero.heroHeadingNoMappedChannel': 'Based on what you\'re studying',
  'hero.practiceProblem': 'Practice Problem',
  'nativeAppBanner.drawer.title': 'Open in ...',
  'nativeAppBanner.drawer.pearsonApp': 'Pearson+ App',
  'nativeAppBanner.banner.title': 'Pearson+ app',
  'nativeAppBanner.banner.description': 'Learn on the go',
  open: 'Open',
  continue: 'Continue',
  'hero.promotionalcard.list1': 'Video singkat yang menguraikan topik-topik sulit',
  'hero.promotionalcard.list2': 'Ribuan soal seperti ujian',
  'hero.promotionalcard.list3': 'Dapakan jawaban dari pakar bidang studi',
  'hero.studyandexamprep': 'Belajar & Persiapan Ujian',
  'hero.SeeAllStudyOptions': 'See all study options',
  'hero.tocLabel': 'View content related to your eTextbook',
  'hero.herobook.mappedChannelHeadingV1': 'Recommended videos and practice questions',
  'hero.popularTopics.headingV1': 'Other popular topics',
  'hero.heroHeadingV1': 'For <span>{replace_value}</span>',
  'hero.learnmore': 'Pelajari selengkapnya',
  'hero.subscriptionStatusTitle': 'Status langganan Pearson+',
  'hero.eTextbook': 'Buku eText',
  'hero.mylabOrMastering': 'MyLab or Mastering',
  'hero.goToPage.label': 'Buka halaman',
  'subscription.eTextbook': 'Buku eText',
  'subscription.mylab': 'MyLab or Mastering',
  'subscription.studyTitle': 'Belajar & Persiapan Ujian',
  'subscription.tryItOut': 'Try it out',
  'print.printAvailTitle': 'Ingin mencetak halaman ini? Berikut ini caranya',
  'print.printAvailDesc': 'Di buku eText, pilih ikon akun di kanan atas. Dari opsi, pilih "Cetak halaman", lalu pilih pengaturan cetak.',
  'print.printNotAvailTitle': 'The print option for this eText is not enabled.',
  'print.printNotAvailDesc': 'For convenient offline access, please download your title through the Pearson+ mobile app and enjoy the flexibility of studying anytime, anywhere!',
  'hero.continueStudying': 'Lanjutkan belajar',
  'hero.header.cta': 'Go to study tools',
  'hero.marketing.title': 'Temukan Buku eText Anda',
  'hero.marketing.subTitle': 'Choose from over 2,000 eTextbooks with audio, flashcards, notes, and more. Only $10.99/mo for a 4-month term.',
  'hero.allyourcourses': 'all your courses',
  'hero.popularchannels.desc': 'Get study help with these top subjects',
  'hero.removecourse.title': 'Hapus kursus dari Dasbor?',
  'hero.removecourse.description': 'Anda selalu dapat menambahkan mata pelajaran ini kembali ke dasbor dengan mengeklik "Tambahkan kursus"',
  'hero.removecourse.primaryCtaText': 'Hapus',
  'hero.removecourse.secondaryCtaText': 'Batal',
  'hero.search': 'Cari',
  'hero.notes': 'Catatan & Sorotan',
  'hero.subscriptionStatusTitle1': 'What\'s Available for you in Pearson+',
  'hero.emptystate.cta': 'Cari buku eText',
  'hero.watch_it_again': 'Watch it again',
  'hero.mondly.learnLang.title': 'Belajar bahasa baru secara gratis',
  'hero.mondly.learnLang.title2': 'Belajar bahasa baru secara gratis!',
  'hero.mondly.subTitle': 'Pengguna Buku eText Pearson mendapatkan tiga bulan gratis Mondly by Pearson.',
  'hero.mondly.description': 'Most language learning apps only let you learn from English. But it\'s best to learn from your native language, so Mondly allows you to learn from any of our <strong>41 languages</strong>.',
  'hero.mondly.marketing.bestApp': 'Best App',
  'hero.mondly.marketing.appStore': 'App Store',
  'hero.mondly.marketing.appYear': 'App of the Year',
  'hero.mondly.marketing.fbStart': 'Facebook FbStart - 2017 EMEA Winner',
  'hero.mondly.marketing.editorChoice': 'Editor Choice',
  'hero.mondly.marketing.googlePlay': 'Google Play',
  'header.menu.learnLanguage': 'Belajar bahasa',
  'hero.subscriptionStatusTitle2': 'Sumber Daya Pearson+ Anda',
  'hero.extractingExamDetails': 'Mengekstrak rincian ujian',
  'hero.finishingTheProcess': 'Menyelesaikan proses',
  'hero.hereWhatWeGotFromYourSyllabus': 'Berikut ini yang kami dapat dari silabus Anda',
  'hero.school': 'Sekolah:',
  'hero.class': 'Kelas:',
  'hero.textbook': 'Buku teks:',
  'hero.examSchedule': 'Jadwal ujian:',
  'hero.confirm': 'Konfirmasi',
  'onboard.purchase': 'Ich muss ein eTextbook von Pearson kaufen',
  'onboard.addCourseWithoutEtext': 'Tambahkan mata pelajaran menggunakan Belajar & Persiapan Ujian',
  'onboard.aboutCourse': 'Beri tahu kami tentang kursus Anda',
  'onboard.studyMaterials': 'Pilih mata pelajaran Anda dan kami akan membuat dasbor dengan materi belajar khusus.',
  'onboard.studyPlan':'Rencana belajar khusus dibuat!',
  'onboard.doYouWantOtherCourse': 'Anda ingin menambahkan mata pelajaran lain?',
  'hero.personalizedStudyPlan': 'Kami akan menggunakan AI untuk memberikan rencana belajar personal.',
  'hero.welcomeToP': 'Selamat datang di P+!',
  'hero.learnYourWayAround': 'Luangkan waktu sebentar untuk memahami cara kerjanya, atau pilih ini nanti di pengaturan akun.',
  'hero.weveCreatedADashboard': 'Kami telah membuat dasbor untuk kursus Anda di sini.',
  'hero.youCanAddYourPearsonCourse': 'Anda juga dapat menambahkan kursus non-Pearson Anda sendiri dan mencoba materi belajar kami secara gratis.',
  'hero.getExamreadyWithVideoLessons': 'Persiapkan diri menghadapi ujian dengan pelajaran video dan rangkaian latihan yang disesuaikan untuk mata pelajaran Anda.',
  'hero.youreTexbook': 'Buku eText Anda dan semua sarana ada di sini.',
  'hero.VideosAndPractice': 'Selalu ada lebih banyak video dan soal latihan yang ada di Belajar dan Persiapan ujian.',
  'hero.pleaseSelectTheChapters': 'Silakan pilih bab yang dibahas dalam ujian ini:',
  'hero.doYouWantToAddACourse': 'Anda ingin menambahkan kursus?',
  'hero.whatsAvailableForYou': 'Yang Tersedia untuk Anda',
  'hero.tableOfContents': 'Daftar Isi',
  'hero.whatsAvailableForYouInPearson': 'Yang Tersedia untuk Anda di Pearson+',
  'hero.startStudying': 'Mulai belajar',
  'hero.mondly.startLearningCTA': 'Mulai belajar',
  'hero.unlockYourStudyExamPrep': 'Unlock your Study & Exam Prep for other Courses now!',
  'onboard.addCourseAnother': 'Anda ingin menambahkan mata pelajaran lain?',
  'hero.getExamreadyForYou': 'Siap ujian untuk mata pelajaran lain menggunakan Belajar & Persiapan Ujian.',
  'hero.weVideoLessons': 'Kami memiliki pelajaran video dan rangkaian latihan yang disesuaikan untuk kebutuhan Anda.',
  'hero.tryForFree': 'Coba secara gratis',
  'hero.heroBook.sectionSubHeadingV1': 'Pecahkan topik sulit dengan pertanyaan latihan dan penjelasan video yang disesuaikan dengan kursus Anda.',
  'hero.heroBook.sectionSubHeadingV2': 'Break down tough topics with concept explanations and practice questions tailored to your course.',
  'hero.addCourseUsingStudyExamPrep': 'Tambahkan mata pelajaran menggunakan Belajar & Persiapan Ujian',
  'hero.for': 'Untuk',
  'header.menu.moreAtPearson': 'Selengkapnya di Pearson',
  'hero.myETextbooks': 'Buku eText saya',
  'header.continueInTheApp': 'Lanjutkan di aplikasi',
  'hero.goToMyLabMastering': 'Kunjungi MyLab/Mastering',
  'hero.learnTxt': 'Pelajari',
  'hero.aITutorTxt': 'Tutor AI',
  'hero.exploreTxt': 'Jelajahi',
  'hero.studyExamPrepAllYourCourses': 'Belajar & Persiapan Ujian untuk semua mata pelajaran',
  'channel.noBundletitle': 'Gunakan Belajar & Persiapan Ujian untuk mata pelajaran terkait & lainnya',
  'hero.examSchedules': 'Jadwal ujian',
  'hero.examSchedules2': 'Jadwal ujian',
  'hero.uploadYourSyllabus': 'Unggah silabus Anda',
  'hero.addAnExam': 'Tambahkan ujian',
  'hero.UploadYourSyllabus.studyExamPrepPack': 'Upload your syllabus to get the most out of your Study & Exam Prep',
  'hero.UploadYourSyllabus.studyExamPrep': 'Unggah silabus Anda untuk mengoptimalkan Belajar & Persiapan Ujian Anda',
  'hero.getRecommendationsOnWhat': 'Dapatkan rekomendasi tentang apa yang perlu dipelajari dan kapan',
  'hero.noSyllabusSharingExamSchedule': 'Tidak ada silabus? Bisa juga dengan membagikan jadwal ujian Anda',
  'hero.selectDropSyllabusFile': 'Pilih atau letakkan file silabus dalam format PDF atau DOCX',
  'hero.uploadTxt': 'Unggah',
  'hero.examName': 'Nama ujian',
  'hero.completeTxt': 'Selesai',
  'hero.exactTopicsChapter': 'Anda akan dapat menentukan topik untuk setiap bab nanti.',
  'course.addTitle': 'Tambahkan kursus',
  'hero.uploadingDocument': 'Mengunggah dokumen',
  'hero.activatingAIComponents': 'Mengaktifkan komponen AI',
  'hero.extractingClassSchoolData': 'Mengekstrak data kelas dan sekolah',
  'hero.extractingTextbookChapters': 'Mengekstrak bab buku teks',
  'onboard.next': 'Berikutnya',
  'onboard.mayBeLater': 'Mungkin nanti',
  'hero.examPrepTxt': 'Persiapan ujian',
  'onboard.addCourseWithoutEtextBundle': 'Tambahkan mata pelajaran menggunakan Belajar & Persiapan Ujian',
  'hero.extractingDataFromSyllabus': 'Mengekstrak data dari silabus...',
  'hero.myLabMastering': 'MyLab/Mastering',
  'AI.toolTip': 'Tanya saya tentang apa saja di buku eText Anda',
  'hero.dateFormat': 'DD/MM/YY',
  'header.qrCode.subHeaderTxt': 'Gunakan kode QR ini untuk masuk dengan cepat dan aman ke aplikasi Pearson+ di perangkat seluler.',
  'header.qrCode.dontHaveApp': 'Tidak punya aplikasinya?',
  'header.qrCode.footerTxt': 'Memindai kode ini akan mengarahkan Anda ke App Store atau Google Play. Setelah aplikasi diunduh, masuk dengan memindainya lagi.',
  'header.qrCode.errorMsg': 'Kode QR tidak dimuat. Tutup dan coba lagi.',
  'hero.yourETextbook': 'Buku eText Anda',
  'onboard.addCourseWithEtextBundle': 'Tambahkan mata pelajaran menggunakan Belajar & Persiapan Ujian',
  'onboard.disableTooltipText': 'All Study & Exam Prep courses are added!',
  'subscriptionstatus.bundlecta': 'Access Study & Exam Prep',
  'subscriptionstatus.nonbundlecta': 'Akses Belajar & Persiapan Ujian',
  'channel.genericBundle': 'Gunakan Belajar & Persiapan Ujian Anda untuk mendapatkan bantuan di mata pelajaran lain',
  'channel.relatedBundle': 'Also use your Study & Exam Prep for help in your other courses',
  'onboard.courseAdded': 'Kursus ditambahkan!',
  'hero.flashcards': 'Flashcard',
  'aichat.title': 'AI Study Tool',
  'aichat.placeholder': 'Which topic or concept from your eTextbook can I explain for you?',
  'aichat.charDesc': 'Ask me anything related to <b>%BOOK_TITLE%</b>.',
  'relatedChannelCard.title': 'Enhance your learning with this related resource',
  'relatedChannelCard.badgeText': 'Related Study & Exam Prep',
  'moremenu.downloadAppNotDownload' : '%Click here% untuk mengunduh aplikasi dan masuk dengan aman menggunakan kode QR unik Anda',
  'moremenu.downloadAppAlreadyDownload': '%Click here% untuk mengunduh aplikasi di perangkat lain dan masuk secara otomatis atau untuk menggunakan kode QR unik untuk masuk dengan aman di aplikasi yang sudah Anda instal.',
  'sessionInactivity.title': 'Are you still there?',
  'sessionInactivity.description': 'Your session will time out in <b>%TIMER%</b> due to inactivity.<br/>Would you like to stay signed in?',
  'sessionInactivity.keepMeSignIn': 'Keep me signed in',
  'sessionInactivity.signOut': 'Sign Out',
  'sessionTimeout.title': 'Session timed out',
  'sessionTimeout.description': 'Your session has timed out due to inactivity.',
  'print.qrCode.header': 'Gunakan kode QR ini untuk membuka aplikasi Pearson+ di perangkat seluler.',
  'print.qrCode.footer': 'Memindai kode ini akan mengarahkan Anda ke App Store atau Google Play. Setelah aplikasi diinstal, masuk dengan mengunduh dan mengakses judul Anda.',
  'hero.nestest.title': 'Prepare for your teacher certification exam',
  'hero.nestest.subTitle': 'Pearson+ users can now access nationally aligned preparation courses and practice tests to prepare for their teacher certification exams.',
  'hero.channelAI.title': 'Need help? AI tutor has you covered.',
  'hero.channelAI.desc': 'Ask a question and get step-by-step homework help, study recommendations, and more — personalized help whenever you need it.',
  'hero.channelAI.action': 'Ask AI Tutor Now',
  new: 'New!'
};

export default id;
