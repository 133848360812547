/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx model(s) related to bookshelf
 *
 * @file Bookshelf.js
 * @author Saravanan Kandasamy
 */

import { types, applySnapshot, getSnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';

import {
  BOOKSHELF_DATA_REQUESTED,
  BOOKSHELF_DATA_FETCHED,
  REMOVE_ENTITLEMENT_SUCCESS,
  ENTITLEMENTLEVEL_MULTI,
  ACTIVE,
  HERO_BANNER_TYPES,
  SET_HERO_DASHBOARD
} from '../../../common/constants';
import ProductUtils from '../../../common/utils/ProductUtils';
import CommonUtils from '../../../common/utils/CommonUtils';
import Book from './Book';
import HeroBannerPlugin from '../../authhome/plugin/HeroBannerPlugin';

/**
 * A mobx model for bookshelf
 *
 */
const Bookshelf = types.model(
  'Bookshelf',
  {
    books: types.array(Book)
  }
).actions(self => ({
  fetch() {
    Framework.getEventManager().publish(BOOKSHELF_DATA_REQUESTED, {});
  },
  set(...data) {
    applySnapshot(self, data[0]);
    // HeroBannerPlugin.setHeroBannerBook(data[2]);
    if(!data[1])
    {
      Framework.getEventManager().publish(BOOKSHELF_DATA_FETCHED, {});
    }
  },
  removeEntitlement(productId, isBanner) {
    const books = self.books.filter(book => book.product_id !== productId);
    if (isBanner) {
      applySnapshot(self.bannerBook, {});
    } else {
      applySnapshot(self.books, books);
      // HeroBannerPlugin.setHeroBannerBook(true);
    }
    Framework.getEventManager().publish(REMOVE_ENTITLEMENT_SUCCESS);
  },
  /**
   * Mapping subscription details to book and setting product model name
   *
   */
  setSubscriptionDetails() {
    const userStore = Framework.getStoreRegistry().getStore('user');
    const user = getSnapshot(userStore);
    const books = getSnapshot(self.books).map((book) => {
      let renderBook = {};
      let gpsSubscriptionOfCurrentBook = null;
      let entitlementTierOfBook = null;
      let entitlementLevelOfBook = null;
      let subscriptionStatusOfBook = null;
      const { subscription_id: subscriptionIdOfBook } = CommonUtils.getProductEntitlementOfBook(book);
      if (subscriptionIdOfBook) {
        const {
          entitlementTier,
          status,
          entitlementLevel,
          subscriptionSource
        } = CommonUtils.getMatchingGpsSubscriptionOfBook(subscriptionIdOfBook, user);
        entitlementTierOfBook = entitlementTier;
        entitlementLevelOfBook = entitlementLevel;
        subscriptionStatusOfBook = status;
        gpsSubscriptionOfCurrentBook = {};
        gpsSubscriptionOfCurrentBook.status = status || null;
        gpsSubscriptionOfCurrentBook.entitlementLevel = entitlementLevel || null;
        gpsSubscriptionOfCurrentBook.subscriptionSource = subscriptionSource || null;
      }
      const productModelName = ProductUtils.getProductModel(book.product_entitlements.business_model_code, entitlementTierOfBook, false);
      const format = ProductUtils.getProductTypeFormat(book.book_type);
      renderBook = {
        ...book,
        product_model_name: `${productModelName}, ${format}`,
        gpsSubscriptionOfBook: gpsSubscriptionOfCurrentBook
      };
      const isActiveBook = CommonUtils.isActiveBook(renderBook);
      return {
        ...renderBook,
        showAudio: book.showAudio && isActiveBook,
        showBookmark: isActiveBook,
        showFlashcard: isActiveBook,
        showRemoveEntitlement: ((entitlementLevelOfBook === ENTITLEMENTLEVEL_MULTI)
          && (subscriptionStatusOfBook === ACTIVE) && isActiveBook)
      };
    });

    applySnapshot(self.books, books);
  },
  /**
   * Archives or unarchives selected books
   *
   * @param {Array} selectedBookIds
   * @param {*} isArchived
   */
  archiveOrUnarchiveSelectedBooks(selectedBookIds = [], isArchived = false) {
    let heroBookId = null;
    const heroBannerStore = Framework.getStoreRegistry().getStore('herobanner');
    const { titleInfo } = getSnapshot(heroBannerStore);
    const books = getSnapshot(self)?.books;
    const updatedBooks = books.map((book) => {
      if (titleInfo?.book_id === book.book_id
        && titleInfo?.product_id === book.product_id
        && titleInfo?.course_id === book.course_id) {
        heroBookId = book.book_id;
      }
      if (selectedBookIds.includes(book.book_id) && book.isArchived !== isArchived) {
        return {
          ...book,
          isArchived
        };
      }

      return { ...book };
    });

    if (updatedBooks.length > 0) {
      applySnapshot(self.books, updatedBooks);
      const { BOOK } = HERO_BANNER_TYPES;
      const fetchChannels = selectedBookIds.includes(heroBookId) || heroBannerStore.bannerType !== BOOK;
      // HeroBannerPlugin.setHeroBannerBook(fetchChannels);
      this.setShowArchiveOrUnarchive(true);
    }
  },
  setShowArchiveOrUnarchive(enableTitleArchival) {
    const { books } = getSnapshot(self);

    const updatedBooks = books.map(book => ({
      ...book,
      showArchive: enableTitleArchival && !book.isArchived,
      showUnArchive: enableTitleArchival && book.isArchived
    }));

    applySnapshot(self.books, updatedBooks);
  },
  setError() {
  }
}));

export default Bookshelf;
