/* eslint-disable class-methods-use-this */
/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2025 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Popular channels service
 *
 * @file Book Preferences.js
 * @author Mohamed yasar arabath M
 */

import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';

export default class BookPreferencesService extends BaseService {
  constructor() {
    super(constants.BOOK_PREFERENCES_REQUESTED, 'BookPreferencesRequested');
  }

  /**
   * Handles book preferences service event
   *
   * @param {*} eventName
   * @param {Object} eventData
   * @returns
   */
  handleEvent(eventName, eventData = {}) {
    const {
      endpointData = {},
      method,
      payload = {}
    } = eventData;
    const {
      userId,
      bookId
    } = endpointData;


    if (method === constants.HTTP_REQUEST_METHODS.POST) {
      return this.getClient('etext')
        .post(`/nextext/users/${userId}/preferences/?bookId=${bookId}`, payload);
    }

    return this.getClient('etext')
      .get(`/nextext/users/${userId}/preferences/?bookId=${bookId}`);
  }

  /**
   * Handles book preferences service event
   *
   * @param {*} response
   */
  handleResponse(response) {
    const responseData = response.data;

    Framework.getEventManager().publish(constants.BOOK_PREFERENCES_FETCHED, responseData);
  }

  /**
   * Handles book preferences service event
   *
   * @param {*} response
   */
  handleError() {
    // eslint-disable-next-line no-console
    Framework.getEventManager().publish(constants.BOOK_PREFERENCES_ERROR);
  }
}
