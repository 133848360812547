/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * MLM home file
 *
 * @file index.js
 * @author Gladson Samuel S
 */

import React, { Suspense } from 'react';
import { MainContainerLoader } from '../authhome/Loader/ComponentLoader';

const MLMAuthHomeContainer = React.lazy(() => import('./MLMAuthHomeContainer'));

export default {
  path: [
    '/home2',
    '/home2/:mlmcontextencoded'
  ],
  exact: true,
  services: [],
  stores: [],
  render: props => (
    <Suspense fallback={<MainContainerLoader />}>
      <MLMAuthHomeContainer {...props} />
    </Suspense>
  )
};
