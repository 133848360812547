/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */

import Framework from '@greenville/framework';

import env from '../env';
import * as constants from '../constants';

export default class PathUtils {
  /**
   * Provides logical path based on the root application
   *
   * @returns string value
   */
  static redirectToReturnUrl = (isStudent) => {
    let returnUrl = `${env.CONSOLE_URL}/console/home`;
    if (!isStudent) {
      returnUrl = PathUtils.getQueryParameterbyName('returnUrl');
    }
    window.location = returnUrl;
  };

  static redirectToPage = (match, history, pageId, isFirstTime = false) => {
    let queryParams = history.location.search || '';
    queryParams = this.removeQueryParameterbyName('chapterId', queryParams);
    // queryParams = this.removeQueryParameterbyName(constants.LMS, queryParams);
    let urlPrefix = match.url;

    if (urlPrefix.indexOf('/contents') > -1) {
      urlPrefix = match.url.split('/contents')[0];
    } else if (match.url.indexOf('/pages/') > -1) {
      urlPrefix = match.url.split('/pages/')[0];
    }
    const printPage = this.getQueryParameterbyName('printPage', queryParams);
    if (printPage) {
      queryParams = this.removeQueryParameterbyName('printPage');
    }
    const threadId = this.getQueryParameterbyName('threadId', queryParams);
    if(threadId && !isFirstTime) {
      queryParams = this.removeQueryParameterbyName('threadId');
    } // Remove Query Params while doing Navigation
    Framework.redirectTo(`${urlPrefix}/pages/${pageId}${queryParams}`);
  };

  static getQueryParameterbyName = (name, searchParams) => {
    const queryParams = searchParams || window.location.search;
    const queryParamObject = new URLSearchParams(queryParams);
    return queryParamObject.get(name);
  };

  static removeQueryParameterbyName(name, searchParams) {
    const queryParams = searchParams || window.location.search;
    if(!name) {
      return queryParams;
    }
    let newQueryParams = '';
    let param = '';
    let paramsArray = [];
    let index = '';
    const queryString = (queryParams.indexOf('?') !== -1) ? queryParams.split('?')[1] : '';
    if (queryString !== '') {
      paramsArray = queryString.split('&');
      for (index = paramsArray.length - 1; index >= 0; index -= 1) {
        param = paramsArray[index].split('=')[0];
        if (param === name) {
          paramsArray.splice(index, 1);
        }
      }
      newQueryParams = `?${paramsArray.join('&')}`;
    }
    return newQueryParams;
  }

  /**
   * Add query param in URL
   *
   * @param {*} queryParam
   * @param {*} href
   * @returns
   */
  static addQueryParameter(queryParam, href) {
    const { URL_QUERY_START_INDICATOR, URL_QUERY_SEPARATOR } = constants;
    let parseUrl = href || window.location.href;
    if (queryParam) {
      const [urlPath, queryString] = parseUrl.split(URL_QUERY_START_INDICATOR);
      if (queryString) {
        const paramsArray = queryString.split(URL_QUERY_SEPARATOR);
        paramsArray.push(queryParam);
        const newQueryParams = `${paramsArray.join(URL_QUERY_SEPARATOR)}`;
        parseUrl = `${urlPath}${URL_QUERY_START_INDICATOR}${newQueryParams}`;
      } else {
        parseUrl = `${urlPath}${URL_QUERY_START_INDICATOR}${queryParam}`;
      }
    }

    return parseUrl;
  }

  static redirectToLink = (url) => {
    window.open(url, '_blank');
  };

  /**
   * Function to get the language code by using language id
   *
   * @param {number} id
   */
  static getPdfLanguageCode = (id) => {
    let locale;

    switch (id) {
      case 1:
      case 2:
        locale = 'en-us';
        break;
      case 4:
        locale = 'it';
        break;
      case 5:
        locale = 'es-mx';
        break;
      case 6:
        locale = 'de';
        break;
      case 7:
        locale = 'nl';
        break;
      case 8:
      case 9:
        locale = 'fr-fr';
        break;
      case 10:
      case 11:
        locale = 'fr-ca';
        break;
      case 12:
        locale = 'ja';
        break;
      case 13:
        locale = 'ko';
        break;
      case 15:
        locale = 'zh-cn';
        break;
      case 18:
      case 28:
        locale = 'pt-br';
        break;
      case 21:
        locale = 'tr';
        break;
      case 22:
        locale = 'pl';
        break;
      case 23:
        locale = 'zh-hk';
        break;
      case 25:
        locale = 'ru';
        break;
      case 26:
        locale = 'es';
        break;
      case 27:
        locale = 'es-co';
        break;
      default:
        locale = 'en-us';
        break;
    }

    return locale;
  }
}
