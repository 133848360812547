/* eslint-disable no-param-reassign */
/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx model(s) related to assets
 *
 * @file Asset.js
 * @author Sandeep
 */

import { types, applySnapshot } from 'mobx-state-tree';
import { toJS } from 'mobx';
import Framework from '@greenville/framework';

import PlaylistUtils from '../../../common/utils/PlaylistUtils';
import TocMapperPdf from '../../../common/utils/TocMapperPdf';

import * as constants from '../../../common/constants';
import env from '../../../common/env';
import CommonUtils from '../../../common/utils/CommonUtils';

/**
 * A mobx model for asset audio meta data
 *
 */
const AssetTypes = types.model(
  'AssetTypes',
  {
    locale: types.maybeNull(types.string),
    gender: types.maybeNull(types.string),
    versionId: types.maybeNull(types.string),
    voiceName: types.maybeNull(types.string),
    uri: types.maybeNull(types.string)
  }
);

const AudioMetaData = types.model(
  'AudioMetaData',
  {
    assetTypes: types.maybeNull(types.array(AssetTypes)),
    basePath: types.maybeNull(types.string),
    srcUri: types.maybeNull(types.string),
    wordBoundaryAvailable: types.maybeNull(types.boolean)
  }
);

/**
 * A mobx model for asset children
 *
 */
const Children = types.model(
  'Children',
  {
    title: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    id: types.maybeNull(types.union(types.string, types.integer, types.number)),
    uri: types.maybeNull(types.union(types.string, types.integer, types.number)),
    playOrder: types.maybeNull(types.union(types.string, types.integer, types.number)),
    sectionType: types.maybeNull(types.string),
    children: types.array((types.late(() => Children))),
    pageno: types.maybeNull(types.union(types.string, types.integer, types.number)),
    linkTypeID: types.maybeNull(types.union(types.string, types.integer, types.number)),
    regionTypeID: types.maybeNull(types.union(types.string, types.integer, types.number)),
    role: types.maybeNull(types.string),
    audio: types.maybeNull(types.array(types.string)),
    audioTTSMetadata: types.maybeNull(types.string),
    optional: types.maybeNull(types.union(types.string, types.boolean)), // TODO Remove union for optional
    versionId: types.maybeNull(types.string),
    parentId: types.maybeNull(types.string),
    parentVersionId: types.maybeNull(types.string),
    usageType: types.maybeNull(types.string)
  }
);

/**
 * A mobx model for asset slates
 *
 */
const Slates = types.model(
  'Slates',
  {
    chapterId: types.maybeNull(types.string),
    chapterUri: types.maybeNull(types.string),
    parentUri: types.maybeNull(types.string),
    moduleUri: types.maybeNull(types.string),
    pageno: types.maybeNull(types.union(types.string, types.integer, types.number)), // TODO Remove union for pageno
    optional: types.maybeNull(types.union(types.string, types.boolean)), // TODO Remove union for optional
    id: types.maybeNull(types.union(types.string, types.integer, types.number)), // TODO Remove union for id
    type: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    uri: types.maybeNull(types.string),
    playOrder: types.maybeNull(types.union(types.string, types.integer, types.number)), // TODO Remove union for playorder
    parentid: types.maybeNull(types.union(types.string, types.integer, types.number)), // TODO Remove union for parentid
    audio: types.maybeNull(types.array(types.string))
  }
);

/**
 * A mobx model for asset
 *
 */
const Asset = types.model(
  'Asset',
  {
    assetId: types.maybeNull(types.string),
    assetVersionId: types.maybeNull(types.string),
    audioMetadata: types.maybeNull(AudioMetaData),
    id: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    playOrder: types.optional(types.string, '0'),
    productId: types.optional(types.string, ''),
    children: types.array(Children),
    slates: types.maybeNull(types.array(Slates))
  }
).views(self => ({
  /**
   * Check whether it is a Chapter node - applicable only for CITE titles
   *
   * @param {Object} item - tocList item
   */
  isChapterNode(item) {
    return (
      this.contentType === constants.CONTENT_TYPE_CITE
        && item.type === 'chapter'
        && item.children.length
    );
  },
  /**
   * Check whether it is a leaf node
   *
   * @param {*} item
   */
  isLeafNode(item, contentType) {
    return ((contentType === constants.CONTENT_TYPE_CITE
    && item.type === 'module'
    && item.children.length
    && item.children[0].href === item.href)
  || item.href === '');
  },
  /**
   * Create course playlist
   *
   * @param {*} event
   */
  createCoursePlaylist(tocData) {
    let prevHref = '';
    const isLeafNode = self.isLeafNode;
    const playlist = [];

    function drillToDeep(tocList, parentTitle) {
      tocList.map((item) => {
        let type;
        let uri;
        let assessmentId;
        let versionUrn;

        if (item.assessmentId) {
          assessmentId = item.assessmentId;
        }
        switch (item.type) {
          case 'quiz':
            type = 'quiz';
            break;
          case 'tdx':
            type = 'tdx';
            break;
          case 'cite-assessment':
            type = 'cite-assessment';
            uri = item.assessmentId;
            break;
          case 'learnosity-assessment':
            type = 'learnosity-assessment';
            versionUrn = item.href;
            break;
          case 'tdx-assessment':
            type = 'tdx-assessment';
            uri = item.assessmentId;
            break;
          case 'glossary':
            type = 'glossary';
            break;
          case 'footnotes':
            type = 'footnotes';
            break;
          default:
            type = 'page';
            assessmentId = null;
        }
        const list = {
          assessmentId,
          chapterId: item.chapterId,
          chapterTitle: parentTitle || item.title,
          href: item.href,
          id: item.id,
          title: item.title,
          type,
          parentItemId: item.parentItemId,
          playOrder: item.playOrder,
          uri,
          urn: item.urn,
          versionUrn
        };
        // To Remove duplicate pages from playlist
        // etext : 'item.coPage' To aviod duplicate pages by neglecting coPage which is chapter
        // revel: 'item.type === 'chapter' && item.children.length' To remove chapter if have children
        // || item.href === prevHref' remove duplicate pages form playlist
        // TODO: - validate coPage logic
        if (!((env.IGNORE_COPAGE && item.coPage)
            || self.isChapterNode(item)
            || (item.href && (item.href === prevHref))
            || isLeafNode(item))
          || item.type === 'cite-assessment'
          || item.type === 'learnosity-assessment') {
          let dehashedURI = '';

          if (item.href.includes('#')) {
            dehashedURI = item.href.split('#')[0];
          }
          if (dehashedURI !== '' && (dehashedURI === prevHref)) {
            // remove duplicate page entry if dehashed href matches
            playlist.pop();

            // Update href and uri without hash
            list.href = dehashedURI;
            list.uri = dehashedURI;
          }
          playlist.push(list);
          prevHref = dehashedURI || item.href;
        }
        const children = item.children;

        if (children.length > 0) {
          drillToDeep(children, item.title);
        }

        return true;
      });
    }
    const tocDataList = tocData && tocData.list;

    if (tocDataList) {
      drillToDeep(tocDataList);
    }

    return playlist;
  },
  /**
   * PlayList contruction for EPUB, BRONTE BV, BRONTE VIEWER, CITE and PXE for AI alone
   *
   * @param {*} format
   * @param {*} tocData
   * @returns
   */
  getAIPagePlaylist(format, tocData = null) {
    const playlist = [];
    /**
     * If book format is Epub
     * Execute below block of code
     */
    if (format === 'EPUB' && self.slates) {
      const flattenedToc = PlaylistUtils.flattenToc(self.children);

      self.slates.forEach((item) => {
        const chapterData = flattenedToc.find(toc => toc.uri === item.parentUri);
        const pageData = {
          chapterId: (chapterData && chapterData.id) || item.id,
          chapterTitle: (chapterData && chapterData.title) || item.title,
          href: item.uri,
          id: item.id,
          title: item.title,
          type: 'page',
          uri: item.uri
        };

        playlist.push(pageData);
      });

      return playlist;
    }

    /**
       * New book format of VIEWER BV
       */
    if ((format === constants.CONTENT_TYPE_BV_BRONTE || format === constants.CONTENT_TYPE_BV_VIEWER) && self.slates) {
      const flattenedToc = PlaylistUtils.flattenToc(self.children);

      self.slates.forEach((item) => {
        const chapterData = flattenedToc.find(toc => toc.id === item.chapterId);
        const pageData = {
          chapterId: (chapterData && chapterData.id) || item.chapterId,
          chapterTitle: (chapterData && chapterData.title) || item.title,
          href: item.uri,
          id: item.id,
          title: item.title,
          type: 'page',
          uri: item.uri
        };

        playlist.push(pageData);
      });

      return playlist;
    }

    /**
       * New book format of cite and pxe
       */
    if (tocData && (format === constants.CONTENT_TYPE_CITE || format === constants.CONTENT_TYPE_PXE)) {
      return self.createCoursePlaylist(tocData);
    }

    return false;
  },

  /**
   * Method to get playlist
   *
   * @param {string} bundleId
   * @param {Object} product
   * @param {Boolean} serverSideEnabled
   */
  getPagePlaylist(bundleId, product, serverSideEnabled) {
    const {
      bookID,
      format,
      model,
      indexId,
      serverSideUuid
    } = product;
    const language = Framework.getStoreRegistry().getStore('language');
    const playlist = [];
    const coverPageUrl = product.getCoverThumbnailUrl();
    const page = language.getMessage('navigation.PAGE');
    let playlistData = bundleId ? self.children : self.slates;

    if (serverSideEnabled) {
      const basepath = product.getBasePath();
      const spdfPath = basepath.split(`ebook${indexId}`)[0].replace('ebookassets', 'pdfassets');
      if (model === constants.ETEXT_PDF && coverPageUrl && !bundleId) {
        const coverPage = {
          href: `${spdfPath}${bookID}/${serverSideUuid}/pages/page0`,
          id: '0',
          index: '0',
          pagenumber: 'Cover',
          playOrder: '0',
          title: 'Cover',
          type: 'page',
          uri: `${spdfPath}${bookID}/${serverSideUuid}/pages/page0`,
          chapterId: 'Cover',
          chapterTitle: 'Cover'
        };

        playlist.push(coverPage);
      }

      playlistData.forEach((item) => {
        const pageData = {
          type: 'page',
          id: `${item.playOrder}`,
          href: `${spdfPath}${bookID}/${serverSideUuid}/pages/page${item.playOrder}`,
          uri: `${spdfPath}${bookID}/${serverSideUuid}/pages/page${item.playOrder}`,
          index: item.playOrder,
          title: `${page} ${item.pageno}`,
          pagenumber: item.pageno,
          _id: item.id,
          playOrder: `${item.playOrder}`,
          chapterId: item.parentid ? item.parentid.toString() : '',
          chapterTitle: item.title,
          actualPageUri: item.uri
        };
        playlist.push(pageData);
      });

      return playlist;
    }
    if (format === constants.CONTENT_TYPE_PDF) {
      if (model === constants.CUSTOM_COLLECTIONS) {
        playlistData = PlaylistUtils.playlistMapper(self.children);
      }
      if (model === constants.ETEXT_PDF && coverPageUrl && !bundleId) {
        const coverPage = {
          href: coverPageUrl,
          id: 'Cover',
          pagenumber: 'Cover',
          playOrder: 'Cover',
          title: 'Cover',
          type: 'page',
          uri: coverPageUrl,
          chapterId: 'Cover',
          chapterTitle: 'Cover'
        };

        playlist.push(coverPage);
      }
      playlistData.forEach((item) => {
        const pageNumber = item.pageno ? (item.pageno).toString() : (item.title).split(' ')[1];
        const playOrder = item.playOrder ? item.playOrder.toString() : '';
        const title = (model === constants.CUSTOM_COLLECTIONS) ? item.title : `${page} ${item.pageno}`;
        const pageData = {
          chapterId: item.parentid ? item.parentid.toString() : '',
          chapterTitle: item.title,
          href: item.uri,
          id: item.pageno ? playOrder : item.id,
          pagenumber: pageNumber,
          parentid: item.parentid,
          playOrder,
          printDisabled: item.optional,
          title,
          type: 'page',
          uri: item.uri,
          actualPageUri: item.uri
        };

        playlist.push(pageData);
      });
      return playlist;
    }
    /**
     * If book format is Epub
     * Execute below block of code
     */
    if (format === 'EPUB' && self.slates) {
      const flattenedToc = PlaylistUtils.flattenToc(self.children);

      self.slates.forEach((item) => {
        const chapterData = flattenedToc.find(toc => toc.uri === item.parentUri);
        const pageData = {
          chapterId: chapterData && chapterData.id || item.id,
          chapterTitle: chapterData && chapterData.title || item.title,
          href: item.uri,
          id: item.id,
          title: item.title,
          type: 'page',
          uri: item.uri
        };

        playlist.push(pageData);
      });

      return playlist;
    }

    /**
     * New book format of VIEWER BV
     */
    if ((format === constants.CONTENT_TYPE_BV_BRONTE || format === constants.CONTENT_TYPE_BV_VIEWER) && self.slates) {
      const flattenedToc = PlaylistUtils.flattenToc(self.children);

      self.slates.forEach((item) => {
        const chapterData = flattenedToc.find(toc => toc.id === item.chapterId);
        const pageData = {
          chapterId: (chapterData && chapterData.id) || item.chapterId,
          chapterTitle: (chapterData && chapterData.title) || item.title,
          href: item.uri,
          id: item.id,
          title: item.title,
          type: 'page',
          uri: item.uri
        };

        playlist.push(pageData);
      });

      return playlist;
    }

    return false;
  },
  /**
   * Method to get pdf toc
   *
   * @param {string} bundleId
   * @param {string} userRole
   * @param {Boolean} serverSideEnabled
   * @param {Boolean} isAudioBook
   */
  getAssetTocPdf(bundleId, userRole, isServerRender, isAudioBook) {
    return TocMapperPdf.tocTraverse(toJS(self), bundleId, userRole, isServerRender, isAudioBook);
  },

  /**
   * Method to get chapter id based on pageId
   * @param {*} pageId
   * @returns
   */

  getChapterId(pageId) {
    const chapter = self.children.find(({ children }) => children.some(({ id }) => id === pageId));
    return (chapter && chapter.id) || null;
  }

})).actions(self => ({

  fetch(productId, courseId, bundleId, format, isIntergratedMLMLaunch = false, isFromAuthHome = false) {
    if (self.id === productId || self.id === courseId) {
      Framework.getEventManager().publish(constants.ASSET_FETCHED, {});
    } else if (!self.loading) {
      self.loading = true; // eslint-disable-line no-param-reassign
      Framework.getEventManager().publish(constants.ASSET_REQUESTED, {
        courseId, productId, bundleId, format, isIntergratedMLMLaunch, isFromAuthHome
      });
    }
  },
  fetchHeroTOC(productId, courseId, bundleId, format, isIntergratedMLMLaunch = false, isFromAuthHome = false) {
    if (self.id === productId || self.id === courseId) {
      Framework.getEventManager().publish(constants.ASSET_FETCHED, {});
    } else {
      Framework.getEventManager().publish(constants.ASSET_REQUESTED, {
        courseId, productId, bundleId, format, isIntergratedMLMLaunch, isFromAuthHome
      });
    }
  },
  setAudioBaseUrl(asset, contentType) {
    const metaBasePath = asset.audioMetadata.basePath;
    const metaDataVersionId = asset.audioMetadata.assetTypes[0].versionId;
    const metaDataAssetTypeUri = asset.audioMetadata.assetTypes[0].uri;
    const wordBoundaryAvailable = asset.audioMetadata.wordBoundaryAvailable;
    let audio = [];
    const getUpdatedUrl = (assets, slates) => {
      assets.forEach((item) => {
        if (item.children) {
          getUpdatedUrl(item.children, slates);
        }
        if ((contentType === constants.CONTENT_TYPE_PXE || contentType === constants.CONTENT_TYPE_CITE) && item.audio) {
          audio = CommonUtils.setUrl(item.audio, metaDataVersionId, metaDataAssetTypeUri, metaBasePath);
          item.audio = audio;
          if (wordBoundaryAvailable && audio.length > 0) {
            item.audioTTSMetadata = audio[0].replace('.mp3', '.json');
            item.audioTTSMetadata = item.audioTTSMetadata.replace(env.ETEXT_URL, env.PLUS_URL);
          }
        } else if (contentType === constants.CONTENT_TYPE_EPUB || contentType === constants.CONTENT_TYPE_BV_BRONTE || contentType === constants.CONTENT_TYPE_BV_VIEWER) {
          // TOTO  - We have to add URI check with #
          const playListAudio = slates && slates.find((list => list.uri === item.uri));
          if (playListAudio && playListAudio.audio) {
            audio = CommonUtils.setUrl(playListAudio.audio, metaDataVersionId, metaDataAssetTypeUri, metaBasePath);
            item.audio = audio;
            if (wordBoundaryAvailable && audio.length > 0) {
              item.audioTTSMetadata = audio[0].replace('.mp3', '.json');
              item.audioTTSMetadata = item.audioTTSMetadata.replace(env.ETEXT_URL, env.PLUS_URL);
            }
          }
        }
      });
      return assets;
    };
    return getUpdatedUrl(asset.children, asset.slates);
  },
  set(asset, isFromCourse, isFromAuthHome = false) {
    const updatedAsset = asset;
    if (!isFromAuthHome) {
      let product = Framework.getStoreRegistry().getStore('product');
      if (isFromCourse) {
        product = Framework.getStoreRegistry().getStore('course').getProduct();
      }
      const contentType = product.format;
      if (asset.audioMetadata) {
        updatedAsset.children = this.setAudioBaseUrl(updatedAsset, contentType);
      }
    }
    applySnapshot(self, updatedAsset);
    Framework.getEventManager().publish(constants.ASSET_FETCHED, {});
  }
}));

export default Asset;
