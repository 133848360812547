/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2025 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mixpanel utilities
 *
 * @file MixpanelUtils.js
 * @author Mohamed yasar arabath M
 */

import CommonUtils from './CommonUtils';

export default class MixpanelUtils {
  static isRecording = false;

  static recordTimer = null;

  /**
   * Mixpanel start session recording
   *
   * @param {*} enableMask
   */
  static startSessionRecording(enableMask = false) {
    this.stopSessionRecordTimer();
    if (window.mixpanel && !this.isRecording) {
      const overRideConfig = enableMask
        ? { record_mask_text_selector: '*', record_block_selector: 'img, video' }
        : { record_mask_text_selector: '', record_block_selector: null };
      this.overRideConfig(overRideConfig);
      window.mixpanel.start_session_recording();
      this.isRecording = true;
    }
  }

  /**
   * start mixpanel Session Recorder with timer
   *
   * @param {*} timeOutMS
   * @returns
   */
  static async startSessionRecordTimer(timeOutMS = 300000) {
    this.startSessionRecording();
    return new Promise((resolve) => {
      this.recordTimer = setTimeout(() => {
        this.stopSessionRecordTimer();
        resolve();
      }, timeOutMS);
    });
  }

  /**
   *  stop Session Recorder Timer
   *
   */
  static stopSessionRecordTimer() {
    clearTimeout(this.recordTimer);
    this.endSessionRecording();
  }

  /**
   * Mixpanel end session recording
   *
   */
  static endSessionRecording() {
    if (window.mixpanel && this.isRecording) {
      window.mixpanel.stop_session_recording();
      this.isRecording = false;
    }
  }

  /**
   * Mixpanel config overRide
   *
   * @param {*} overRideConfig
   */
  static overRideConfig(overRideConfig) {
    if (window.mixpanel && !CommonUtils.isEmpty(overRideConfig)) {
      window.mixpanel.set_config({ ...overRideConfig });
    }
  }
}
