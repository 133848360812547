/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Translation file for Portuguese(Brasil) language
 *
 * @file pt-br.js
 * @author Manimaran.S
 */

const ptBr = {
  'popularChannelSlider.allChannels': 'Todos os canais',
  'popularChannelSlider.recommended': 'Recomendado',
  'channel.noBundleRelatedtitle': 'Pesquise materiais de preparação para estudos e exames relacionados às suas outras aulas.',
  'hero.myetextbook.link': 'eTextbook',
  'hero.myetextbook.text': 'Não é o título certo? Acesse sua biblioteca de',
  'hero.ctanewtext': 'Open Study & Exam Prep',
  'hero.welcomeToPPlus': 'Boas-vindas à Pearson+',
  'hero.etextfeaturestitle': 'Encontre materiais de estudo em seu eTextbook',
  'hero.etextfeaturesdescription': 'O seu eTextbook vem com materiais de estudo desenvolvidos para ajudar você a ter sucesso. Estude com flashcards e muito mais sem sair do seu eTextbook.',
  'aichat.title1': 'Obtenha ajuda alimentada por AI para tirar qualquer dúvida sobre este eTextbook',
  'aichat.chatDesc1': 'Digite uma pergunta para começar',
  'aichat.title2': 'Obtenha ajuda alimentada por IA',
  'aichat.chatDesc2': 'Tire qualquer dúvida sobre este eTextbook',
  'subscriptiontext.isSubscribed': 'Parte da sua assinatura',
  'subscriptiontext.nonSubscriber': 'Atualize para ter acesso completo',
  'topicConatiner.otherHeader': 'Related video lessons & practice',
  'topicConatiner.exactLiteHeader': 'Prática relacionada',
  'authHome.h1': 'Authenticated Home',
  'moremenu.downloadApp': 'Download the app',
  'moremenu.downloadAppAlreadyDownload': '%Click here% para baixar o aplicativo em outro dispositivo e entrar automaticamente ou para usar seu código QR exclusivo para entrar com segurança no aplicativo que você já instalou.',
  'moremenu.downloadAppNotDownload': '%Click here% para baixar o aplicativo e entrar com segurança com seu código QR exclusivo',
  'channel.noBundletitle': 'Use a preparação para estudos e exames para seus cursos relacionados e outros cursos',
  'onboard.next': 'Próximo',
  'onboard.studyPlan': 'Foi criado um plano de estudo personalizado!',
  'onboard.addCourseAnother': 'Você deseja adicionar outro curso?',
  'onboard.purchase': 'Preciso adquirir um eTextbook da Pearson',
  'onboard.addCourseWithoutEtext': 'Adicione curso usando a preparação para estudos e exames',
  'onboard.addCourseWithoutEtextBundle': 'Adicione curso usando a preparação para estudos e exames',
  'onboard.doYouWantOtherCourse': 'Você deseja adicionar outro curso?',
  'onboard.mayBeLater': 'Talvez mais tarde',
  'onboard.close': 'Close',
  'course.addTitle': 'Adicionar curso',
  'bookshelf.TITLE': 'Minha estante de livros',
  'common.LOGOUT': 'Sair',
  'common.GO_HOME': 'Ir para página inicial',
  'common.PURCHASE': 'Comprar',
  'courses.WELCOME': 'Olá {userName}, boas vindas ao seu curso {courseName} !',
  'courses.action.SHOW_ASSIGNMENTS': 'Mostrar Tarefas',
  'courses.toc.PUBLISH_SUCCESS': 'Sucesso!',
  'courses.toc.PUBLISH_MESSAGE': 'Os alunos verão as alterações na próxima vez que abrirem o conteúdo.',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'Assinatura não encontrada',
  'errors.SUBSCRIPTION_GENERIC_DESC': `Sua conta não inclui acesso para este título. Se você acredita que receber esta mensagem tenha
      sido errado, entre em contato com o Suporte Técnico da Pearson`,
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'Assinatura não encontrada',
  'errors.SUBSCRIPTION_REGISTRATION_DESC': `Sua assinatura não inclui acesso ao eText, porém você pode assinar agora mesmo utilizando 
    um cartão de crédito.`,
  'foxit.ABOUT': 'Sobre',
  'foxit.COPY_RIGHT': 'Leitor de PDF distribuído pela Foxit. Direito autoral (C) 2003-2019 Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonPrivacyPolicy.html',
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonEULA.html',
  'menu.GET_THE_APP_DOWNLOAD': 'Baixar o aplicativo móvel',
  'menu.CONTACT_SUPPORT': 'Entrar em contato com Suporte',
  'menu.ABOUT': 'Sobre',
  'menu.FOXIT': 'Distribuído pela Foxit',
  'menu.TERMS_OF_USE': 'Termos de Uso',
  'menu.PRIVACY_POLICY': 'Política de Privacidade',
  'menu.SIGN_OUT': 'Desconectar',
  'menu.PRINT_PAGE': 'Imprimir página',
  'menu.GET_LOOSE_LEAF': 'Obter versão de folhas soltas',
  'menu.AVAILABLE': 'Também disponível',
  'menu.PRINT_VERSION': 'Deseja a versão impressa??',
  'menu.COPY_RIGHT': 'Direito autoral \u00A9 {year} Pearson Education Inc. Todos os direitos reservados.',
  'menu.tooltip.SCHEDULE': 'Agenda',
  'print.WATERMARK': 'Direito autoral © {year} Pearson Education Inc.',
  'product.WELCOME': 'Olá {userName}',
  'device.SWAPPED_ALREADY': 'Você já fez a troca este mês',
  'device.SWITCH_DEVICE': 'Mudar para o atual dispositivo?',
  'device.SWITCH_ONCE': 'Como só é possível fazer uma troca por mês, você não poderá desfazer esta ação.',
  'device.SEAT_TAKEN': 'Essa vaga está ocupada',
  'device.DISCONNECT_DEVICE': 'Você só pode fazer login em 3 dispositivos. Para continuar aqui, desconecte outro dispositivo.',
  'device.ALERT_TEXT': 'Lembre-se de que você pode trocar de dispositivos uma vez por mês.',
  'device.YOUR_MOJO': 'Você está prestes a acessar o mojo',
  'device.DISCONNECT': 'Desconectar',
  'device.CONTINUE': 'Continuar',
  'device.HEADER': 'End your other browser session?',
  'device.CLOSE': 'Voltar para a página inicial',
  'device.CODE': 'Continue here by entering the authentication code we sent to {maskedEmail}. Not working? Check your spam folder, {email}, or contact {support}.',
  'device.TIMER': 'Você tem {time} para digitar seu código aqui',
  'device.RESEND': '{resend}',
  'device.FAQ': 'FAQ',
  'device.RESEND_NAME': 'Resend',
  'device.NOTE': 'If you regularly use Pearson+ in 2 places, like at work and at home, make sure to log out whenever you’re done. You can also use the Pearson+ mobile app on up to 2 devices. For more info, see our {faq}.',
  'device.NOTE_HEADER': 'Avoid this next time',
  'device.CONTACT_SUPPORT': '',
  'device.CONTACT_EMAIL': '',
  'device.CONTACT_FAQ': '',
  'device.INCORRECT': 'Incorreto. Verifique novamente o código ou reenvie-o.',
  'device.MANY_ATTEMPTS': 'Várias tentativas. Tente reenviar o código',
  'device.EXPIRED': 'Seu tempo esgotou. Tente reenviar o código',
  'device.ACCESS_HEADER': 'Deu certo!',
  'device.ACCESS_DESC': 'Sua sessão foi autenticada. Gostaria de atualizar sua senha enquanto está logado?',
  'device.ACCESS_CHANGE_PASSWORD': 'Alterar senha',
  'device.ACCESS_RESET_PASSWORD': 'Redefinir senha',
  'device.THREATONE_TITLE': 'Tudo certo?',
  'device.THREATONE_DESC': 'Notamos uma atividade suspeita em sua conta.',
  'device.THREATONE_DESC_TWO': 'Esta é uma boa hora para alterar sua senha.',
  'device.MAY_BE_LATER': 'Talvez mais tarde',
  'device.OKAY': 'Okay',
  'device.ACCOUNT_LOCK_DESC': 'Para manter a segurança, vamos encerrar a sua sessão. Nós enviamos um link de redefinição de senha ao e-mail vinculado à sua conta.',
  'device.ACCOUNT_LOCK_TITLE': 'Wir haben eine verdächtige Aktivität auf Ihrem Konto festgestellt',
  'device.ACCOUNT_LOCK_TIMER': 'Sie werden abgemeldet in {time}',
  'menu.myAccount': 'Minha conta',
  'menu.helpCenter': 'Central de ajuda',
  'menu.preferencesCenter': 'Central de preferências',
  'menu.HELP_SUPPORT': 'Ajuda e suporte',
  'menu.FAQS': 'Perguntas frequentes',
  'menu.CHAT_WITH_SUPPORT': 'Fale com o suporte',
  'menu.QUICK_WALKTHROUGH': 'Apresentação rápida',
  'menu.LEGAL': 'Jurídico',
  'menu.PRIVACY_NOTICE': 'Aviso de privacidade',
  'menu.TITLE_INFO': 'Informações do título',
  'menu.PEARSON_ACCESSIBILITY': 'Acessibilidade da Pearson',
  'menu.DEVICE_WARNING_MESSAGE': 'Registre {deviceLimit} dispositivos. Faça uma troca por mês.',
  'menu.ACCESSIBILITY': 'Acessibilidade',
  'menu.ACCESSIBILITY_SUPPORT': 'Apoio à acessibilidade',
  'menu.DEVICES': 'Dispositivos recentes',
  'menu.THIS_DEVICE': 'Este dispositivo',
  'menu.MOJO_APP_INFO': 'Acesse a loja de aplicativos do seu celular ou tablet para usar o Pearson+ off-line.',
  'menu.DEVICE_LABEL': 'Dispositivo',
  'navigation.PAGE': 'Erro de',
  'launchcode.CREATEBY': 'Criado por:',
  'launchcode.sentvia': 'Enviar por:',
  'launchcode.CODEINLAST': 'Códigos dos últimos 7 dias:',
  'launchcode.TIMESENT': 'Horário de envio:',
  'launchcode.ACCOUNTEMAIL': 'E-mail da conta:',
  'launchcode.LAUNCHDIALOGTITLE': 'Log de código OTP',
  'menu.OTP_CODES': 'Códigos OTP',
  'menu.LAUNCH_CODE': 'Iniciar log de código',
  'menu.SEND_CODE': 'Enviar novo código do usuário por e-mail',
  'menu.SEND_CODE_PHONE': 'Enviar novo código do usuário por mensagem de texto',
  'menu.COMMUNITY_GUIDELINES': 'Diretrizes da comunidade',
  'textmessage.TITLE': 'Inserir o número de telefone',
  'textmessage.PHONE_NUMBER': 'Número de telefone',
  'textmessage.SEND_CODE': 'Enviar código',
  'textmessage.INVALID_NUMBER': 'Número de celular inválido.',
  'textmessage.SUCCESS_MESSAGE': 'Código enviado',
  'search.searchPlaceHolderPPlus': 'Search by ISBN, Title, or Author',
  'search.searchPlaceHolderEText': 'Pesquisar sua lista',
  'remove.title': 'Remover título?',
  'remove.subTitle': 'Você pode recuperar quando quiser. Basta usar a pesquisa para adicionar novamente.',
  'remove.buttonText': 'Remover',
  'footer.downloadMobileApp': 'Download o aplicativo móvel',
  'footer.termsOfUse': 'Termos de uso',
  'footer.privacy': 'Privacidade',
  'footer.cookies': 'Cookies',
  'footer.doNotSellMyPersonalInformation': 'Não venda minhas informações pessoais',
  'footer.accessibility': 'Acessibilidade',
  'footer.patentNotice': 'Aviso de patente',
  'footer.copyrights': 'Pearson Todos os direitos reservados.',
  'channel.videos': 'Vídeos',
  'channel.questions': 'Perguntas',
  'channel.descriptionA': 'Complemente sua leitura com os Canais.',
  'channel.descriptionB': 'Obtenha ajuda para assuntos difíceis com os Canais.',
  'channel.openchannel': 'Abrir canal',
  'hero.continueReading': 'Continuar leitura',
  'hero.openBook': 'Abrir livro',
  'hero.channel.open': 'Abrir canal',
  'hero.addATitle': 'Adicionar um título',
  'hero.toc.defaultPlacehoder': '--- select a topic ---',
  'hero.viewInactiveTitles': 'Exibir títulos inativos',
  'library.marketingCard': {
    title: 'Choose from over 2,000 eTextbooks',
    subTitle: 'with audio, flashcards, notes, and more.',
    primaryButton: {
      text: 'Encontre seu eTextbook'
    },
    secondaryButton: {
      text: 'View all titles'
    }
  },
  'hero.mappedChannelV2': {
    ariaLabel: 'channel',
    heading: 'Recommended study help Channel',
    callForAction: {
      text: 'Open'
    }
  },
  'hero.openEtextBook': 'Abrir eTextbook',
  'hero.openEtextBookV1': 'Abrir eTextbook',
  'hero.heroHeading': 'Porque está estudando <span>{channel_title}</span>',
  'hero.heroHeadingForBundleUser': 'Study & Exam Prep',
  'hero.study.help': 'Receba ajuda nos estudos',
  'hero.study.jumpBackIn': 'Voltar',
  'hero.study.numOfUsers': 'No momento, há <b>{num_of_users}</b> alunos usando os canais.',
  'hero.heroBook.sectionHeading': 'Destrave em seu curso',
  'hero.heroBook.channelsSectionHeading': 'Iniciar uma nova sessão de estudo',
  'hero.heroBook.sectionHeadingNewUser': 'A ajuda de estudo está aqui',
  'hero.heroBook.sectionSubHeading': 'Analise tópicos difíceis com questões práticas e explicações em vídeo adaptadas ao seu curso.',
  'hero.heroChannel.cardsHeading': 'Continue de onde você parou',
  'hero.marketingcard.subheading': 'Estude de forma mais eficiente com vídeos que explicam conceitos complicados e problemas para treino, todos personalizados ao seu curso.',
  'hero.marketingcard1.copy.title': 'Aprenda com vídeos',
  'hero.marketingcard1.copy.description': 'Assista a vídeos curtos com explicações que explicam tópicos complicados.',
  'hero.marketingcard2.copy.title': 'Problemas para treino',
  'hero.marketingcard2.copy.description': 'Prepare-se para suas provas com milhares de questões semelhantes às da prova e resoluções em vídeo',
  'hero.marketingcard3.copy.title': 'Obtenha ajuda especializada',
  'hero.marketingcard3.copy.description': 'Poste perguntas e obtenha respostas de especialistas',
  'hero.popularTopics.heading': 'Outros tópicos populares neste canal',
  'hero.popularChannels.heading': 'Canais populares',
  'hero.popularChannels.headingv1': 'Está fazendo outro curso?',
  'hero.popularChannels.description': 'Receba ajuda nos estudos com esses principais canais.',
  'hero.popularChannels.descriptionv1': 'Obtenha ajuda em outro curso escolhendo um canal.',
  'hero.popularChannels.descriptionv2': 'Navegue pelos tópicos populares de ajuda de estudo.',
  'hero.startReading': 'Iniciar leitura',
  'hero.practice': 'Praticar',
  'hero.noTitleNoChannel.sectionHeading': 'Prepare-se para a prova em tempo recorde',
  'hero.herobook.mappedChannelHeading': 'Ajuda de estudo recomendada neste canal',
  'libraryL2.myBooks': 'My Books',
  'libraryL2.archived': 'Archived',
  'hero.heroHeadingNoMappedChannel': 'Based on what you\'re studying',
  'hero.practiceProblem': 'Practice Problem',
  'nativeAppBanner.drawer.title': 'Open in ...',
  'nativeAppBanner.drawer.pearsonApp': 'Pearson+ App',
  'nativeAppBanner.banner.title': 'Pearson+ app',
  'nativeAppBanner.banner.description': 'Learn on the go',
  open: 'Open',
  continue: 'Continue',
  'hero.promotionalcard.list1': 'Vídeos curtos que explicam tópicos complicados',
  'hero.promotionalcard.list2': 'Milhares de questões semelhantes às da prova',
  'hero.promotionalcard.list3': 'Obtenha respostas de especialistas',
  'hero.studyandexamprep': 'Preparação para estudos e exames',
  'hero.SeeAllStudyOptions': 'See all study options',
  'hero.tocLabel': 'View content related to your eTextbook',
  'hero.herobook.mappedChannelHeadingV1': 'Recommended videos and practice questions',
  'hero.popularTopics.headingV1': 'Other popular topics',
  'hero.heroHeadingV1': 'For <span>{replace_value}</span>',
  'hero.learnmore': 'Saiba mais',
  'hero.subscriptionStatusTitle': 'Status de assinatura Pearson+',
  'hero.eTextbook': 'eTextbook',
  'hero.mylabOrMastering': 'MyLab or Mastering',
  'subscription.eTextbook': 'eTextbook',
  'subscription.mylab': 'MyLab or Mastering',
  'subscription.studyTitle': 'Preparação para estudos e exames',
  'subscription.tryItOut': 'Try it out',
  'print.printAvailTitle': 'Deseja imprimir esta página? Veja como.',
  'print.printAvailDesc': 'Em seu eTextbook, selecione o ícone de conta no canto superior direito. Em opções, escolha “Imprimir página” e, em seguida, escolha suas configurações de impressão.',
  'print.printNotAvailTitle': 'The print option for this eText is not enabled.',
  'print.printNotAvailDesc': 'For convenient offline access, please download your title through the Pearson+ mobile app and enjoy the flexibility of studying anytime, anywhere!',
  'hero.header.cta': 'Go to study tools',
  'hero.marketing.title': 'Encontre seu eTextbook',
  'hero.marketing.subTitle': 'Choose from over 2,000 eTextbooks with audio, flashcards, notes, and more. Only $10.99/mo for a 4-month term.',
  'hero.allyourcourses': 'all your courses',
  'hero.popularchannels.desc': 'Get study help with these top subjects',
  'hero.removecourse.title': 'Remover o curso do Painel de Controle?',
  'hero.removecourse.description': 'Sempre que quiser, você pode adicionar esse curso de volta ao seu painel clicando em “Adicionar curso”',
  'hero.removecourse.primaryCtaText': 'Remover',
  'hero.removecourse.secondaryCtaText': 'Cancelar',
  'hero.subscriptionStatusTitle1': 'What\'s Available for you in Pearson+',
  'hero.emptystate.cta': 'Pesquisar eTextbooks',
  'hero.watch_it_again': 'Watch it again',
  'hero.mondly.learnLang.title': 'Comece a aprender um novo idioma gratuitamente',
  'hero.mondly.learnLang.title2': 'Comece a aprender um novo idioma gratuitamente!',
  'hero.mondly.subTitle': 'Usuários de eTextbooks da Pearson têm três meses gratuitos no Mondly da Pearson.',
  'hero.mondly.description': 'Most language learning apps only let you learn from English. But it\'s best to learn from your native language, so Mondly allows you to learn from any of our 41 languages.',
  'hero.mondly.startLearningCTA': 'Comece a aprender',
  'hero.mondly.marketing.bestApp': 'Best App',
  'hero.mondly.marketing.appStore': 'App Store',
  'hero.mondly.marketing.appYear': 'App of the Year',
  'hero.mondly.marketing.fbStart': 'Facebook FbStart - 2017 EMEA Winner',
  'hero.mondly.marketing.editorChoice': 'Editor Choice',
  'hero.mondly.marketing.googlePlay': 'Google Play',
  'header.menu.moreAtPearson': 'Mais na Pearson',
  'hero.extractingExamDetails': 'Extraindo informações do exame',
  'hero.finishingTheProcess': 'Concluindo o processo',
  'hero.hereWhatWeGotFromYourSyllabus': 'Confira todas as informações que extraímos do seu currículo',
  'hero.school': 'Escola:',
  'hero.class': 'Turma:',
  'hero.textbook': 'Livro:',
  'hero.examSchedule': 'Cronograma dos exames:',
  'hero.confirm': 'Confirmar',
  'onboard.aboutCourse': 'Conte para nós sobre seu curso',
  'onboard.studyMaterials': 'Escolha um assunto e criaremos um painel com materiais de estudo personalizados.',
  'hero.personalizedStudyPlan': 'Usaremos a IA para criar um plano de estudos personalizado para você.',
  'hero.welcomeToP': 'Boas-vindas à P+!',
  'hero.learnYourWayAround': 'Reserve um minuto do seu tempo para explorar; se quiser fazer isso depois, é só acessar as configurações da conta.',
  'hero.weveCreatedADashboard': 'Criamos um painel para o seu curso aqui.',
  'hero.youCanAddYourPearsonCourse': 'Você também pode adicionar cursos que não sejam da Pearson e conferir os materiais de estudo de graça.',
  'libraryL2.back': 'Anterior',
  'hero.getExamreadyWithVideoLessons': 'Prepare-se para o exame com aulas de vídeo e práticas específicas para seu curso.',
  'hero.youreTexbook': 'Aqui você encontra seu eTextbook e todas as ferramentas.',
  'hero.VideosAndPractice': 'Sempre há mais vídeos e perguntas práticas disponíveis na preparação para estudos e exames.',
  'hero.pleaseSelectTheChapters': 'Selecione os capítulos abrangidos no exame:',
  'hero.tableOfContents': 'Índice',
  'hero.search': 'Pesquisar',
  'hero.notes': 'Anotações e destaques',
  'hero.doYouWantToAddACourse': 'Deseja adicionar um curso?',
  'hero.whatsAvailableForYou': 'O que está disponível para você',
  'hero.whatsAvailableForYouInPearson': 'O que está disponível para você na Pearson+',
  'hero.continueStudying': 'Continuar estudando',
  'hero.startStudying': 'Começar a estudar',
  'hero.unlockYourStudyExamPrep': 'Unlock your Study & Exam Prep for other Courses now!',
  'header.menu.learnLanguage': 'Aprenda um idioma',
  'hero.subscriptionStatusTitle2': 'Seus recursos da Pearson+',
  'hero.goToPage.label': 'Ir para a página',
  'hero.getExamreadyForYou': 'Prepare-se para as provas de seus outros cursos usando a preparação para estudos e exames.',
  'hero.weVideoLessons': 'Temos aulas de vídeo e práticas específicas personalizadas para as suas necessidades.',
  'hero.tryForFree': 'Aprenda de graça',
  'hero.heroBook.sectionSubHeadingV1': 'Analise tópicos difíceis com questões práticas e explicações em vídeo adaptadas ao seu curso.',
  'hero.heroBook.sectionSubHeadingV2': 'Break down tough topics with concept explanations and practice questions tailored to your course.',
  'hero.addCourseUsingStudyExamPrep': 'Adicione curso usando a preparação para estudos e exames',
  'hero.for': 'Para',
  'hero.myETextbooks': 'Meus eTextbooks',
  'header.continueInTheApp': 'Continuar no aplicativo',
  'hero.goToMyLabMastering': 'Acessar MyLab/Mastering',
  'hero.learnTxt': 'Aprender',
  'hero.aITutorTxt': 'Tutor de IA',
  'hero.exploreTxt': 'Explorar',
  'hero.studyExamPrepAllYourCourses': 'Preparação para estudos e exames para todos os cursos',
  'hero.examSchedules': 'Cronograma dos exames',
  'hero.examSchedules2': 'Cronograma dos exames',
  'hero.uploadYourSyllabus': 'Enviar currículo',
  'hero.addAnExam': 'Adicionar exame',
  'hero.UploadYourSyllabus.studyExamPrepPack': 'Upload your syllabus to get the most out of your Study & Exam Prep',
  'hero.UploadYourSyllabus.studyExamPrep': 'Envie seu currículo para aproveitar tudo o que a preparação para estudos e exames oferece',
  'hero.getRecommendationsOnWhat': 'Receba recomendações sobre o que e quando estudar',
  'hero.noSyllabusSharingExamSchedule': 'Não tem nenhum currículo? Outra opção é compartilhar o cronograma dos exames',
  'hero.selectDropSyllabusFile': 'Selecione ou arraste e solte um arquivo de currículo nos formatos PDF ou DOCX',
  'hero.uploadTxt': 'Enviar',
  'hero.examName': 'Nome do exame',
  'hero.completeTxt': 'Concluir',
  'hero.exactTopicsChapter': 'Posteriormente, você poderá especificar os assuntos exatos de cada capítulo.',
  'hero.uploadingDocument': 'Enviando documento',
  'hero.activatingAIComponents': 'Ativando componentes de IA',
  'hero.extractingClassSchoolData': 'Extraindo dados da turma e da escola',
  'hero.extractingTextbookChapters': 'Extraindo capítulos do livro',
  'hero.examPrepTxt': 'Preparação para exames',
  'hero.extractingDataFromSyllabus': 'Extraindo dados do currículo...',
  'hero.myLabMastering': 'MyLab/Mastering',
  'AI.toolTip': 'Tire qualquer dúvida comigo sobre o seu eTextbook',
  'hero.dateFormat': 'DD/MM/YY',
  'header.qrCode.subHeaderTxt': 'Use este código QR para fazer login de forma rápida e segura no aplicativo Pearson+ em seu dispositivo móvel.',
  'header.qrCode.dontHaveApp': 'Não tem o aplicativo?',
  'header.qrCode.footerTxt': 'A leitura desse código redirecionará você para a App Store ou Google Play. Depois que o aplicativo for baixado, leia o código novamente para fazer login.',
  'header.qrCode.errorMsg': 'O código QR não carregou. Feche e tente novamente.',
  'hero.yourETextbook': 'Seu eTextbook',
  'onboard.addCourseWithEtextBundle': 'Adicione curso usando a preparação para estudos e exames',
  'onboard.disableTooltipText': 'All Study & Exam Prep courses are added!',
  'subscriptionstatus.bundlecta': 'Access Study & Exam Prep',
  'subscriptionstatus.nonbundlecta': 'Acessar a Preparação para estudos e exames',
  'channel.genericBundle': 'Use a sua preparação para estudos e exames para ajudar em seus outros cursos',
  'channel.relatedBundle': 'Also use your Study & Exam Prep for help in your other courses',
  'onboard.courseAdded': 'Curso adicionado',
  'hero.flashcards': 'Flashcards',
  'aichat.title': 'AI Study Tool',
  'aichat.placeholder': 'Which topic or concept from your eTextbook can I explain for you?',
  'aichat.charDesc': 'Ask me anything related to <b>%BOOK_TITLE%</b>.',
  'relatedChannelCard.title': 'Enhance your learning with this related resource',
  'relatedChannelCard.badgeText': 'Related Study & Exam Prep',
  'sessionInactivity.title': 'Are you still there?',
  'sessionInactivity.description': 'Your session will time out in <b>%TIMER%</b> due to inactivity.<br/>Would you like to stay signed in?',
  'sessionInactivity.keepMeSignIn': 'Keep me signed in',
  'sessionInactivity.signOut': 'Sign Out',
  'sessionTimeout.title': 'Session timed out',
  'sessionTimeout.description': 'Your session has timed out due to inactivity.',
  'print.qrCode.header': 'Use este código QR para abrir o aplicativo Pearson+ em seu dispositivo móvel.',
  'print.qrCode.footer': 'A leitura desse código redirecionará você para a App store ou o Google Play. Depois que o aplicativo for instalado, faça login para baixar e acessar seu título.',
  'hero.nestest.title': 'Prepare for your teacher certification exam',
  'hero.nestest.subTitle': 'Pearson+ users can now access nationally aligned preparation courses and practice tests to prepare for their teacher certification exams.',
  'hero.channelAI.title': 'Need help? AI tutor has you covered.',
  'hero.channelAI.desc': 'Ask a question and get step-by-step homework help, study recommendations, and more — personalized help whenever you need it.',
  'hero.channelAI.action': 'Ask AI Tutor Now',
  new: 'New!'
};

export default ptBr;
