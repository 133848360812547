/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Component loaders component
 *
 * @file MarqueeLoader.jsx
 * @author Gladson Samuel S
 */

import React, { Fragment } from 'react';
import LoadingCardSkeleton from './LoadingCardSkeleton';
import './loader.scss';

// eslint-disable-next-line import/prefer-default-export
export const HeaderLoader = () => (
  <div className="loading-container loading-header-container">
    <div className="loading-header">
      <LoadingCardSkeleton skeletonStyle="loading-header-logo" />
      <LoadingCardSkeleton skeletonStyle="loading-header-search" />
      <div className="loader-header-links">
        <LoadingCardSkeleton skeletonStyle="loading-header-pearson" />
        <LoadingCardSkeleton skeletonStyle="loading-header-etextbook" />
      </div>
      <div className="loader-header-buttons">
        <LoadingCardSkeleton skeletonStyle="loading-header-qr" />
        <LoadingCardSkeleton skeletonStyle="loading-header-profilesection" />
      </div>
    </div>
  </div>
);

export const CourseSectionLoader = () => (
  <div className="loading-coursesection-container">
    <div className="loading-coursesection">
      <LoadingCardSkeleton skeletonStyle="loading-coursesection-addbutton" />
      <div className="loader-coursesection-slider">
        <LoadingCardSkeleton skeletonStyle="loading-coursesection-card" />
        <LoadingCardSkeleton skeletonStyle="loading-coursesection-card" />
        <LoadingCardSkeleton skeletonStyle="loading-coursesection-card" />
        <LoadingCardSkeleton skeletonStyle="loading-coursesection-card" />
        <LoadingCardSkeleton skeletonStyle="loading-coursesection-card" />
        <LoadingCardSkeleton skeletonStyle="loading-coursesection-card" />
        <LoadingCardSkeleton skeletonStyle="loading-coursesection-card" />
        <LoadingCardSkeleton skeletonStyle="loading-coursesection-card" />
        <LoadingCardSkeleton skeletonStyle="loading-coursesection-card" />
        <LoadingCardSkeleton skeletonStyle="loading-coursesection-card" />
        <LoadingCardSkeleton skeletonStyle="loading-coursesection-card" />
      </div>
    </div>
  </div>
);


export const HeroGenericShimmerLeftContainer = () => (
  <div className="loading-herobook-containers">
    <LoadingCardSkeleton skeletonStyle="loading-heroBook-container-heading" />
    <LoadingCardSkeleton skeletonStyle="loading-heroBook-container-topic" />
  </div>
);
export const HeroGenericShimmerLeft = () => (
  <div className="loading-herobook-left-container">
    <HeroGenericShimmerLeftContainer />
    <HeroGenericShimmerLeftContainer />
  </div>

);


export const HeroGenericShimmerRightContainer = () => (
  <Fragment>
    <div className="loading-herobook-right-container-card">
      <LoadingCardSkeleton skeletonStyle="loading-herobook-right-container-card-heading" />
      <LoadingCardSkeleton skeletonStyle="loading-herobook-right-container-card-description" />
      <LoadingCardSkeleton skeletonStyle="loading-herobook-right-container-card-footer" />
    </div>
    <div className="loading-herobook-right-container-card">
      <LoadingCardSkeleton skeletonStyle="loading-herobook-right-container-studyexam-section" />
    </div>


  </Fragment>


);

export const HeroGenericShimmerRight = () => (
  <div className="loading-herobook-right-container">
    <HeroGenericShimmerRightContainer />
  </div>
);

// eslint-disable-next-line react/prop-types
export const HeroGenericShimmer = ({ withoutWrapper = false }) => (
  <Fragment>
    {withoutWrapper
      ? (
        <Fragment>
          <div className="loading-heroBook-container-main-heading">
            <LoadingCardSkeleton skeletonStyle="loading-herobook-heading" />
            <div className="loading-heroBook-container">
              <HeroGenericShimmerRight />
              <HeroGenericShimmerLeft />
            </div>
          </div>
        </Fragment>
      )
      : (
        <div className="">
          <div className="loading-heroBook-container">
            <HeroGenericShimmerRight />
            <HeroGenericShimmerLeft />
          </div>
        </div>
      )
    }
  </Fragment>
);

export const ChannelsLoader = () => (
  <div className="loading-container sliderchannel-container">
    <div>
      <LoadingCardSkeleton skeletonStyle="loading-channels-title" />
    </div>
    <div className="loading-channels-wrapper">
      <div className="channelcard1"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
      <div className="channelcard2"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
      <div className="channelcard3"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
      <div className="channelcard4"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
      <div className="channelcard6"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
      <div className="channelcard7"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
      <div className="channelcard8"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
      <div className="channelcard9"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
      <div className="channelcard10"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
      <div className="channelcard11"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
      <div className="channelcard12"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
    </div>
  </div>
);

export const ChannelsSliderLoader = () => (
  <div className="loading-container sliderchannel-container">
    <div>
      <LoadingCardSkeleton skeletonStyle="loading-channels-title" />
    </div>
    <div className="loading-channels-wrapper">
      <div className="channelcard1"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
      <div className="channelcard2"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
      <div className="channelcard3"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
      <div className="channelcard4"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
      <div className="channelcard6"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
      <div className="channelcard7"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
      <div className="channelcard8"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
      <div className="channelcard9"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
      <div className="channelcard10"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
      <div className="channelcard11"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
      <div className="channelcard12"><LoadingCardSkeleton skeletonStyle="loading-channels-card" /></div>
    </div>
  </div>
);

export const CommonLoader = () => (
  <></>
);

export const MainContainerLoader = () => (
  <div className="loading-main-container" />
);
