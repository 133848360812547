/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * TemplateH
 *
 * @file TemplateH.jsx
 * @author Mohamed yasar arabath M
 */

import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import NotificationBanner from '../../../common/components/banners/notificationbanners/NotificationBanner';

const TemplateH = ({ data, callbackForBraze }) => {
  const notificationBannerRef = useRef(null);
  const {
    styles,
    title,
    showDismiss,
    action = []
  } = data;
  const {
    onViewCard,
    onDismiss,
    logContentCardClick
  } = callbackForBraze;
  const linkTypeAction = action.filter(({ type }) => type === 'link');
  const dismiss = (event = null) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    logContentCardClick();
    onDismiss();
  };
  const handleLinkClick = (actionData) => {
    const {
      target,
      launchURL,
      logClickEvent
    } = actionData;

    if (logClickEvent) {
      logContentCardClick(actionData.id);
    }
    if (launchURL) {
      window.open(launchURL, target || '_self');
    }
  };

  useEffect(() => {
    if (data) {
      onViewCard();
      linkTypeAction.forEach((linkAction) => {
        const bannerLink = notificationBannerRef.current.querySelector(`.MuiAlert-message a[data-id='${linkAction.id}']`);
        if (bannerLink) {
          bannerLink.addEventListener('click', () => handleLinkClick(linkAction));
        }
      });
    }

    return () => {
      linkTypeAction.forEach((linkAction) => {
        const bannerLink = notificationBannerRef.current?.querySelector(`.MuiAlert-message a[data-id='${linkAction.id}']`);
        if (bannerLink) {
          bannerLink.removeEventListener('click', () => handleLinkClick(linkAction));
        }
      });
    };
  }, [data]);

  return (
    data && (
      <NotificationBanner
        ref={notificationBannerRef}
        styles={styles || {}}
        message={title}
        showDismiss={showDismiss}
        onClose={dismiss}
      />
    )
  );
};

TemplateH.defaultProps = {
  data: {
    styles: {},
    showDismiss: false,
    action: []
  },
  callbackForBraze: {
    onViewCard: () => {},
    onDismiss: () => {},
    logContentCardClick: () => {}
  }
};

TemplateH.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    styles: PropTypes.object,
    showDismiss: PropTypes.bool,
    action: PropTypes.array
  }),
  callbackForBraze: PropTypes.shape({
    onViewCard: PropTypes.func,
    onDismiss: PropTypes.func,
    logContentCardClick: PropTypes.func
  })
};

export default memo(TemplateH);
