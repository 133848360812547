/* eslint-disable class-methods-use-this */
/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2025 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Channel service
 *
 * @file ChannelDetailsService.js
 * @author Ujjwal Sana
 */

import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';

export default class ChannelDetailsService extends BaseService {
  constructor() {
    super(constants.CHANNEL_DETAILS_REQUESTED);
  }

  /**
   * Trigger the course details api for lightweight
   *
   * @returns
   */
  handleEvent(eventName, channelId) {
    return this.getClient('studyChannelStatic').get(
      `/auth-home/courses/${channelId}/details`
    );
  }

  /**
   * Handle course details api response
   *
   * @param {*} response
   */
  handleResponse(response) {
    Framework.getStoreRegistry().getStore('herobanner').addChannelData(response.data);
  }

  /**
   * Error handling
   *
   * @param {*} error
   */
  handleError(error) {
    console.log('Channels service error', error);
  }
}
