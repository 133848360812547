/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Translation file for Spanish language
 *
 * @file es.js
 * @author Manimaran.S
 */

const es = {
  'popularChannelSlider.allChannels': 'Todos los canales',
  'popularChannelSlider.recommended': 'Recomendados',
  'channel.noBundleRelatedtitle': 'Explore el paquete de Estudio y preparación para exámenes relacionado con sus otras clases.',
  'hero.myetextbook.link': 'biblioteca',
  'hero.myetextbook.text': '¿No es el título correcto? Acceda a su libro de texto electrónico',
  'hero.ctanewtext': 'Open Study & Exam Prep',
  'hero.welcomeToPPlus': 'Le damos la bienvenida a Pearson+',
  'hero.etextfeaturestitle': 'Encuentre materiales de estudio en su libro de texto electrónico',
  'hero.etextfeaturesdescription': 'Su libro de texto electrónico viene con materiales de estudio diseñados para ayudarle a aprender. Estudie con tarjetas didácticas y mucho más directamente en su libro de texto electrónico.',
  'aichat.title1': 'Obtenga ayuda impulsada por IA para cualquier cosa relacionada con este libro de texto electrónico.',
  'aichat.chatDesc1': 'Escriba una pregunta para empezar',
  'aichat.title2': 'Obtenga ayuda impulsada por IA',
  'aichat.chatDesc2': 'Pregunte sobre cualquier cosa relacionada con este libro de texto electrónico',
  'subscriptiontext.isSubscribed': 'Parte de su suscripción',
  'subscriptiontext.nonSubscriber': 'Actualice para un acceso completo',
  'topicConatiner.otherHeader': 'Related video lessons & practice',
  'topicConatiner.exactLiteHeader': 'Prácticas relacionadas',
  'authHome.h1': 'Authenticated Home',
  'moremenu.downloadApp': 'Download the app',
  'moremenu.downloadAppAlreadyDownload': '%Click here% para descargar la aplicación en otro dispositivo e iniciar sesión automáticamente o para usar su código QR exclusivo para iniciar sesión de forma segura en la aplicación que ya ha instalado.',
  'moremenu.downloadAppNotDownload': '%Click here% para descargar la aplicación e iniciar sesión de forma segura con su código QR exclusivo',
  'channel.noBundletitle': 'Utilice el paquete de Estudio y preparación para exámenes para sus cursos relacionados y otros cursos',
  'onboard.next': 'Siguiente',
  'onboard.studyPlan': '¡Plan de estudio personalizado creado!',
  'onboard.addCourseAnother': '¿Quiere añadir otro curso?',
  'onboard.addCourseWithoutEtextBundle': 'Añadir curso con Estudio y preparación para exámenes',
  'onboard.doYouWantOtherCourse': '¿Quiere añadir otro curso?',
  'onboard.purchase': 'Ich muss ein eTextbook von Pearson kaufen',
  'onboard.addCourseWithoutEtext': 'Añadir curso con Estudio y preparación para exámenes',
  'onboard.mayBeLater': 'Quizás luego',
  'onboard.aboutCourse': 'Cuéntenos sobre su curso',
  'onboard.close': 'Close',
  'course.addTitle': 'Añadir curso',
  'bookshelf.TITLE': 'Mi estantería',
  'common.LOGOUT': 'Cerrar sesión',
  'common.GO_HOME': 'Ir al Inicio',
  'common.PURCHASE': 'Comprar',
  'courses.WELCOME': '¡Hola {userName}, bienvenido a su curso {courseName}!',
  'courses.action.SHOW_ASSIGNMENTS': 'Mostrar asignaciones',
  'courses.toc.PUBLISH_SUCCESS': '¡Éxito!',
  'courses.toc.PUBLISH_MESSAGE': 'Los estudiantes verán sus cambios la próxima vez que abran contenido.',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'Suscripción no encontrada',
  'errors.SUBSCRIPTION_GENERIC_DESC': `Su cuenta no incluye acceso a este título. Si cree que recibió este mensaje por error,
      comuníquese con el Servicio de Asistencia Técnica de Pearson.`,
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'Suscripción no encontrada',
  'errors.SUBSCRIPTION_REGISTRATION_DESC': `Su suscripción no incluye acceso a eText, sin embargo,
      puede suscribirse ahora mismo con una tarjeta de crédito.`,
  'foxit.ABOUT': 'Acerca de',
  'foxit.COPY_RIGHT': 'Lector de PDF potenciado por Foxit. Copyright (C) 2003-2019 por Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonPrivacyPolicy.html',
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonEULA.html',
  'menu.CONTACT_SUPPORT': 'Contactar al Servicio de Asistencia Técnica',
  'menu.ABOUT': 'Acerca de',
  'menu.GET_THE_APP_DOWNLOAD': 'Descargar la aplicación móvil',
  'menu.FOXIT': 'Potenciado por Foxit',
  'menu.TERMS_OF_USE': 'Términos de uso',
  'menu.PRIVACY_POLICY': 'Política de privacidad',
  'menu.SIGN_OUT': 'Cerrar sesión',
  'menu.PRINT_PAGE': 'Imprimir página',
  'menu.GET_LOOSE_LEAF': 'Obtener hoja suelta',
  'menu.AVAILABLE': 'También disponible',
  'menu.PRINT_VERSION': '¿Desea la versión impresa?',
  'menu.COPY_RIGHT': 'Copyright \u00A9 {year} Pearson Education Inc. Todos los derechos reservados.',
  'menu.tooltip.SCHEDULE': 'Programar',
  'print.WATERMARK': 'Copyright © {year} Pearson Education',
  'product.WELCOME': 'Hola {userName}',
  'device.SWAPPED_ALREADY': 'Ya ha hecho un intercambio este mes',
  'device.SWITCH_DEVICE': '¿Cambiar al dispositivo actual?',
  'device.SWITCH_ONCE': 'Dado que solo puede cambiar una vez al mes, no podrá deshacer esta acción.',
  'device.SEAT_TAKEN': 'Ese asiento está ocupado',
  'device.DISCONNECT_DEVICE': 'Solo puede iniciar sesión en 3 dispositivos. Para continuar aquí, desconecte otro dispositivo.',
  'device.ALERT_TEXT': 'Recuerde que solo puede cambiar los dispositivos una vez al mes.',
  'device.YOUR_MOJO': 'Está en camino hacia Mojo',
  'device.DISCONNECT': 'Desconectar',
  'device.CONTINUE': 'Continuar',
  'device.HEADER': 'End your other browser session?',
  'device.CLOSE': 'Regresar a inicio',
  'device.CODE': 'Continue here by entering the authentication code we sent to {maskedEmail}. Not working? Check your spam folder, {email}, or contact {support}.',
  'device.TIMER': 'Tiene {time} para ingresar su código aquí',
  'device.RESEND': '{resend}',
  'device.FAQ': 'FAQ',
  'device.RESEND_NAME': 'Resend',
  'device.NOTE': 'If you regularly use Pearson+ in 2 places, like at work and at home, make sure to log out whenever you’re done. You can also use the Pearson+ mobile app on up to 2 devices. For more info, see our {faq}.',
  'device.NOTE_HEADER': 'Avoid this next time',
  'device.CONTACT_SUPPORT': '',
  'device.CONTACT_EMAIL': '',
  'device.CONTACT_FAQ': '',
  'device.INCORRECT': 'Incorrecto: vuelva a verificar el código o solicite que se le reenvíe.',
  'device.MANY_ATTEMPTS': 'Realizó demasiados intentos, pruebe solicitar el reenvío del código',
  'device.EXPIRED': 'El tiempo ha expirado, pruebe solicitar el reenvío del código',
  'device.ACCESS_HEADER': '¡Ha ingresado!',
  'device.ACCESS_DESC': 'Su sesión se ha autenticado. ¿Quiere aprovechar a actualizar su contraseña?',
  'device.ACCESS_CHANGE_PASSWORD': 'Cambiar contraseña',
  'device.ACCESS_RESET_PASSWORD': 'Reiniciar contraseña',
  'device.THREATONE_TITLE': '¿Está todo bien?',
  'device.THREATONE_DESC': '"Notamos una actividad sospechosa en su cuenta."',
  'device.THREATONE_DESC_TWO': 'Este es un buen momento para cambiar su contraseña.',
  'device.MAY_BE_LATER': 'Quizás luego',
  'device.OKAY': 'Aceptar',
  'device.ACCOUNT_LOCK_DESC': 'Por seguridad, cerraremos la sesión. Le enviamos un enlace de restablecimiento de contraseña al correo electrónico asociado con su cuenta.',
  'device.ACCOUNT_LOCK_TITLE': 'Notamos una actividad sospechosa en su cuenta',
  'device.ACCOUNT_LOCK_TIMER': 'La sesión se cerrará en {time}',
  'menu.myAccount': 'Mi cuenta',
  'menu.helpCenter': 'Centro de asistencia',
  'menu.preferencesCenter': 'Centro de preferencias',
  'menu.HELP_SUPPORT': 'Ayuda y soporte',
  'menu.FAQS': 'Preguntas frecuentes',
  'menu.CHAT_WITH_SUPPORT': 'Hable con soporte técnico',
  'menu.QUICK_WALKTHROUGH': 'Guía rápida',
  'menu.LEGAL': 'Legal',
  'menu.PRIVACY_NOTICE': 'Aviso de privacidad',
  'menu.TITLE_INFO': 'Información del título',
  'menu.PEARSON_ACCESSIBILITY' : 'Accesibilidad Pearson',
  'menu.DEVICE_WARNING_MESSAGE': 'Registre {deviceLimit} dispositivos. Intercambie 1 al mes.',
  'menu.ACCESSIBILITY': 'Accesibilidad',
  'menu.ACCESSIBILITY_SUPPORT': 'Soporte de accesibilidad',
  'menu.DEVICES': 'Dispositivos recientes',
  'menu.THIS_DEVICE': 'Este dispositivo',
  "menu.MOJO_APP_INFO": "Visite la tienda de aplicaciones en su teléfono inteligente o tableta para usar Pearson+ sin conexión.",
  "menu.DEVICE_LABEL": "Dispositivo",
  'navigation.PAGE': 'Página',
  'launchcode.CREATEBY': 'Creado por:',
  'launchcode.sentvia': 'Enviado a través de:',
  'launchcode.CODEINLAST': 'Códigos de los últimos siete días:',
  'launchcode.TIMESENT': 'Hora de envío:',
  'launchcode.ACCOUNTEMAIL':'Correo electrónico de la cuenta:',
  'launchcode.LAUNCHDIALOGTITLE': 'Registro de códigos de OTP',
  'menu.OTP_CODES': 'Códigos de OTP',
  'menu.LAUNCH_CODE': 'Iniciar el registro de códigos',
  'menu.SEND_CODE': 'Enviar un código nuevo al usuario por correo electrónico',
  'menu.SEND_CODE_PHONE': 'Enviar un código nuevo al usuario por mensaje de texto',
  'menu.COMMUNITY_GUIDELINES': 'Normas de la comunidad',
  'textmessage.TITLE': 'Ingresar número de teléfono',
  'textmessage.PHONE_NUMBER': 'Número de teléfono',
  'textmessage.SEND_CODE': 'Enviar código',
  'textmessage.INVALID_NUMBER': 'El número de teléfono móvil no es válido.',
  'textmessage.SUCCESS_MESSAGE': 'Código enviado',
  'search.searchPlaceHolderPPlus': 'Search by ISBN, Title, or Author',
  'search.searchPlaceHolderEText': 'Buscar en su lista',
  'remove.title': '¿Desea eliminar el título?',
  'remove.subTitle': 'Puede volver a recuperarlo más adelante, simplemente utilice la búsqueda para volver a agregarlo.',
  'remove.buttonText': 'Eliminar',
  'footer.downloadMobileApp': 'Descargar la aplicación móvil',
  'footer.termsOfUse': 'Términos de uso',
  'footer.privacy': 'Privacidad',
  'footer.cookies': 'Cookies',
  'footer.doNotSellMyPersonalInformation': 'No vendan mi información personal',
  'footer.accessibility': 'Accesibilidad',
  'footer.patentNotice': 'Aviso de patente',
  'footer.copyrights': 'Pearson Todos los derechos reservados.',
  'channel.videos': 'Vídeos',
  'channel.questions': 'Preguntas',
  'channel.descriptionA': 'Complemente su lectura con los Canales.',
  'channel.descriptionB': 'Obtenga ayuda sobre temas difíciles con los Canales.',
  'channel.openchannel': 'Abrir canal',
  'hero.continueReading': 'Continuar leyendo',
  'hero.openBook': 'Abrir libro',
  'hero.channel.open': 'Abrir canal',
  'hero.addATitle': 'Añadir un título',
  'hero.toc.defaultPlacehoder': '--- select a topic ---',
  'hero.viewInactiveTitles': 'Ver títulos inactivos',
  'library.marketingCard': {
    title: 'Choose from over 2,000 eTextbooks',
    subTitle: 'with audio, flashcards, notes, and more.',
    primaryButton: {
      text: 'Encuentre su libro electrónico'
    },
    secondaryButton: {
      text: 'View all titles'
    }
  },
  'hero.mappedChannelV2': {
    ariaLabel: 'channel',
    heading: 'Recommended study help Channel',
    callForAction: {
      text: 'Open'
    }
  },
  'hero.openEtextBook': 'Abrir el libro electrónico',
  'hero.openEtextBookV1': 'Abrir el libro electrónico',
  'hero.heroHeading': 'Porque está estudiando <span>{channel_title}</span>',
  'hero.heroHeadingForBundleUser': 'Study & Exam Prep',
  'hero.study.help': 'Obtenga ayuda para el estudio',
  'hero.study.jumpBackIn': 'Volver a acceder',
  'hero.study.numOfUsers': 'Actualmente, <b>{num_of_users}</b> estudiantes están usando Canales.',
  'hero.heroBook.sectionHeading': 'No se quede atascado en el curso',
  'hero.heroBook.channelsSectionHeading': 'Inicie una sesión de estudio nueva',
  'hero.heroBook.sectionHeadingNewUser': 'Encuentre ayuda para el estudio aquí',
  'hero.heroBook.sectionSubHeading': 'Profundice en los distintos temas con preguntas de práctica y explicaciones en vídeo adaptadas a su curso.',
  'hero.heroChannel.cardsHeading': 'Continúe donde se quedó',
  'hero.marketingcard.subheading': 'Estudie de manera más eficaz con vídeos en los que se analizan los conceptos y problemas de práctica adicionales adaptados a su curso.',
  'hero.marketingcard1.copy.title': 'Aprenda con vídeos',
  'hero.marketingcard1.copy.description': 'Vea vídeos explicativos breves en los que se analizan temas complicados.',
  'hero.marketingcard2.copy.title': 'Problemas de práctica',
  'hero.marketingcard2.copy.description': 'Prepárese para su examen con miles de preguntas similares al examen y vídeos con soluciones.',
  'hero.marketingcard3.copy.title': 'Obtenga ayuda experimentada',
  'hero.marketingcard3.copy.description': 'Publique preguntas y reciba respuestas de expertos en el tema.',
  'hero.popularTopics.heading': 'Otros temas populares de este canal',
  'hero.popularChannels.heading': 'Canales populares',
  'hero.popularChannels.headingv1': '¿Está realizando otro curso?',
  'hero.popularChannels.description': 'Obtenga ayuda para el estudio con los principales Canales.',
  'hero.popularChannels.descriptionv1': 'Seleccione un canal para obtener ayuda con otro cursos.',
  'hero.popularChannels.descriptionv2': 'Explore temas populares de ayuda con el estudio.',
  'hero.startReading': 'Comenzar a leer',
  'hero.practice': 'Practicar',
  'hero.noTitleNoChannel.sectionHeading': 'Prepárese para el examen en un tiempo récord',
  'hero.herobook.mappedChannelHeading': 'Ayuda con el estudio recomendada en este canal',
  'libraryL2.myBooks': 'My Books',
  'libraryL2.archived': 'Archived',
  'libraryL2.back': 'Atrás',
  'hero.heroHeadingNoMappedChannel': 'Based on what you\'re studying',
  'hero.practiceProblem': 'Practice Problem',
  'nativeAppBanner.drawer.title': 'Open in ...',
  'nativeAppBanner.drawer.pearsonApp': 'Pearson+ App',
  'nativeAppBanner.banner.title': 'Pearson+ app',
  'nativeAppBanner.banner.description': 'Learn on the go',
  open: 'Open',
  continue: 'Continue',
  'hero.promotionalcard.list1': 'Vea vídeos tutoriales en los que se analizan temas complicados',
  'hero.promotionalcard.list2': 'Miles de preguntas similares al examen',
  'hero.promotionalcard.list3': 'Reciba respuestas de expertos en el tema',
  'hero.studyandexamprep': 'Estudio y preparación para exámenes',
  'hero.heroBook.sectionSubHeadingV1': 'Break down tough topics with video explanations and practice questions tailored to your course.',
  'hero.heroBook.sectionSubHeadingV2': 'Break down tough topics with concept explanations and practice questions tailored to your course.',
  'hero.SeeAllStudyOptions': 'See all study options',
  'hero.tocLabel': 'View content related to your eTextbook',
  'hero.herobook.mappedChannelHeadingV1': 'Recommended videos and practice questions',
  'hero.popularTopics.headingV1': 'Other popular topics',
  'hero.heroHeadingV1': 'For <span>{replace_value}</span>',
  'hero.learnmore': 'Leer más',
  'hero.subscriptionStatusTitle': 'Estado de suscripción a Pearson+',
  'hero.eTextbook': 'Libro electrónico',
  'hero.mylabOrMastering': 'MyLab or Mastering',
  'hero.goToPage.label': 'Ir a la página',
  'subscription.eTextbook': 'Libro electrónico',
  'subscription.mylab': 'MyLab or Mastering',
  'subscription.studyTitle': 'Estudio y preparación para exámenes',
  'subscription.tryItOut': 'Try it out',
  'print.printAvailTitle': '¿Quiere imprimir esta página? Así puede',
  'print.printAvailDesc': 'En su libro de texto electrónico, seleccione el icono de cuenta en la parte superior derecha. Desde las opciones, elija "Imprimir página" y luego escoja sus ajustes de impresión.',
  'print.printNotAvailTitle': 'The print option for this eText is not enabled.',
  'print.printNotAvailDesc': 'For convenient offline access, please download your title through the Pearson+ mobile app and enjoy the flexibility of studying anytime, anywhere!',
  'hero.continueStudying': 'Continuar estudiando',
  'hero.header.cta': 'Go to study tools',
  'hero.marketing.title': 'Encuentre su libro electrónico',
  'hero.marketing.subTitle': 'Choose from over 2,000 eTextbooks with audio, flashcards, notes, and more. Only $10.99/mo for a 4-month term.',
  'hero.allyourcourses': 'all your courses',
  'hero.popularchannels.desc': 'Get study help with these top subjects',
  'hero.removecourse.title': '¿Eliminar el curso del Tablero?',
  'hero.removecourse.description': 'Siempre puede volver a añadir este curso a su tablero haciendo clic en "Añadir curso".',
  'hero.removecourse.primaryCtaText': 'Eliminar',
  'hero.removecourse.secondaryCtaText': 'Cancelar',
  'hero.tableOfContents': 'Índice',
  'hero.search': 'Buscar',
  'hero.notes': 'Notas y puntos destacados',
  'hero.subscriptionStatusTitle1': 'What\'s Available for you in Pearson+',
  'hero.emptystate.cta': 'Buscar libros de texto electrónicos',
  'hero.watch_it_again': 'Watch it again',
  'hero.mondly.learnLang.title': 'Aprenda un nuevo idioma gratis',
  'hero.mondly.learnLang.title2': 'Aprenda un nuevo idioma gratis!',
  'hero.mondly.subTitle': 'Los usuarios de libros de texto electrónicos de Pearson obtienen tres meses gratis de Mondly de Pearson.',
  'hero.mondly.description': 'Most language learning apps only let you learn from English. But it\'s best to learn from your native language, so Mondly allows you to learn from any of our <strong>41 languages</strong>.',
  'hero.mondly.startLearningCTA': 'Comenzar a aprender',
  'hero.mondly.marketing.bestApp': 'Best App',
  'hero.mondly.marketing.appStore': 'App Store',
  'hero.mondly.marketing.appYear': 'App of the Year',
  'hero.mondly.marketing.fbStart': 'Facebook FbStart - 2017 EMEA Winner',
  'hero.mondly.marketing.editorChoice': 'Editor Choice',
  'hero.mondly.marketing.googlePlay': 'Google Play',
  'header.menu.learnLanguage': 'Aprenda un idioma',
  'hero.subscriptionStatusTitle2': 'Sus recursos de Pearson+',
  'hero.extractingExamDetails': 'Extrayendo los detalles de los exámenes',
  'hero.finishingTheProcess': 'Finalizando el proceso',
  'hero.hereWhatWeGotFromYourSyllabus': 'Esto es lo que obtuvimos de su temario',
  'hero.school': 'Escuela:',
  'hero.class': 'Clase:',
  'hero.textbook': 'Libro de texto:',
  'hero.examSchedule': 'Calendario de exámenes:',
  'hero.confirm': 'Confirmar',
  'onboard.studyMaterials': 'Choose your subject and we\'ll create a dashboard with customized study materials.',
  'hero.personalizedStudyPlan': 'Utilizaremos IA para ofrecerle un plan de estudio personalizado.',
  'hero.welcomeToP': '¡Le damos la bienvenida P+!',
  'hero.learnYourWayAround': 'Tómese un minuto para familiarizarse, también puede retomarlo en otro momento en los ajustes de su cuenta.',
  'hero.weveCreatedADashboard': 'We’ve created a dashboard for your course here.',
  'hero.youCanAddYourPearsonCourse': 'También puede añadir sus cursos que no sean de Pearson y probar material de estudio gratis.',
  'hero.getExamreadyWithVideoLessons': 'Prepárese para los exámenes con vídeos de lecciones y prácticas personalizadas para su curso.',
  'hero.youreTexbook': 'Puede encontrar su libro electrónico y todas sus herramientas aquí.',
  'hero.VideosAndPractice': 'Siempre hay más vídeos y preguntas de práctica disponibles en Estudio y preparación para exámenes.',
  'hero.pleaseSelectTheChapters': 'Seleccione los capítulos que se incluyen en este examen:',
  'hero.doYouWantToAddACourse': '¿Quiere añadir un curso?',
  'hero.whatsAvailableForYou': 'Opciones disponibles para usted',
  'hero.whatsAvailableForYouInPearson': 'Opciones disponibles para usted en Pearson+',
  'hero.startStudying': 'Comenzar a estudiar',
  'hero.unlockYourStudyExamPrep': 'Unlock your Study & Exam Prep for other Courses now!',
  'hero.getExamreadyForYou': 'Prepárese para los exámenes de sus demás cursos con Estudio y preparación para exámenes.',
  'hero.weVideoLessons': 'Tenemos vídeos de lecciones y prácticas personalizadas para sus necesidades.',
  'hero.tryForFree': 'Probar gratis',
  'hero.addCourseUsingStudyExamPrep': 'Añadir curso con Estudio y preparación para exámenes',
  'hero.for': 'Para',
  'header.menu.moreAtPearson': 'Más en Pearson',
  'hero.myETextbooks': 'Mis libros electrónicos',
  'header.continueInTheApp': 'Continuar en la aplicación',
  'hero.goToMyLabMastering': 'Ir a MyLab/Mastering',
  'hero.learnTxt': 'Aprender',
  'hero.aITutorTxt': 'Tutor con IA',
  'hero.exploreTxt': 'Explorar',
  'hero.studyExamPrepAllYourCourses': 'Estudio y preparación para exámenes para todos sus cursos',
  'hero.examSchedules': 'Calendario de exámenes',
  'hero.examSchedules2': 'Calendario de exámenes',
  'hero.uploadYourSyllabus': 'Cargar el temario',
  'hero.addAnExam': 'Añadir un examen',
  'hero.UploadYourSyllabus.studyExamPrepPack': 'Upload your syllabus to get the most out of your Study & Exam Prep',
  'hero.UploadYourSyllabus.studyExamPrep': 'Cargue su temario para sacar el máximo provecho a Estudio y preparación para exámenes',
  'hero.getRecommendationsOnWhat': 'Reciba recomendaciones sobre qué estudiar y cuándo hacerlo',
  'hero.noSyllabusSharingExamSchedule': '¿No tiene temario? También puede compartir su calendario de exámenes',
  'hero.selectDropSyllabusFile': 'Seleccione o suelte un documento con el temario en formato PDF o DOCX',
  'hero.uploadTxt': 'Cargar',
  'hero.examName': 'Nombre del examen',
  'hero.completeTxt': 'Completar',
  'hero.exactTopicsChapter': 'Podrá especificar los temas exactos de cada capítulo más adelante.',
  'hero.uploadingDocument': 'Cargando el documentos',
  'hero.activatingAIComponents': 'Activando componentes de IA',
  'hero.extractingClassSchoolData': 'Extrayendo información de la clase y la escuela',
  'hero.extractingTextbookChapters': 'Extrayendo los capítulos del libro de texto',
  'hero.examPrepTxt': 'Preparación para exámenes',
  'hero.extractingDataFromSyllabus': 'Extrayendo información del temario...',
  'hero.myLabMastering': 'MyLab/Mastering',
  'AI.toolTip': 'Pregúnteme cualquier cosa de su libro de texto electrónico',
  'hero.dateFormat': 'DD/MM/YY',
  'header.qrCode.subHeaderTxt': 'Use este código QR para iniciar sesión de forma rápida y segura en la aplicación Pearson+ de su dispositivo móvil.',
  'header.qrCode.dontHaveApp': '¿No tiene la aplicación?',
  'header.qrCode.footerTxt': 'Escaneando este código, se le redirigirá a la App Store o a Google Play. Después de haber descargado la aplicación, inicie sesión escaneándolo de nuevo.',
  'header.qrCode.errorMsg': 'No se cargó el código QR. Cierre e intente nuevamente.',
  'hero.yourETextbook': 'Su libro de texto electrónico.',
  'onboard.addCourseWithEtextBundle': 'Añadir curso con Estudio y preparación para exámenes',
  'onboard.disableTooltipText': 'All Study & Exam Prep courses are added!',
  'subscriptionstatus.bundlecta': 'Access Study & Exam Prep',
  'subscriptionstatus.nonbundlecta': 'Acceso a Estudio y preparación para exámenes',
  'channel.genericBundle': 'Utilice Estudio y preparación para exámenes para sus otros cursos',
  'channel.relatedBundle': 'Also use your Study & Exam Prep for help in your other courses',
  'onboard.courseAdded': '¡Curso añadido!',
  'hero.flashcards': 'Tarjetas didácticas',
  'aichat.title': 'AI Study Tool',
  'aichat.placeholder': 'Which topic or concept from your eTextbook can I explain for you?',
  'aichat.charDesc': 'Ask me anything related to <b>%BOOK_TITLE%</b>.',
  'relatedChannelCard.title': 'Enhance your learning with this related resource',
  'relatedChannelCard.badgeText': 'Related Study & Exam Prep',
  'sessionInactivity.title': 'Are you still there?',
  'sessionInactivity.description': 'Your session will time out in <b>%TIMER%</b> due to inactivity.<br/>Would you like to stay signed in?',
  'sessionInactivity.keepMeSignIn': 'Keep me signed in',
  'sessionInactivity.signOut': 'Sign Out',
  'sessionTimeout.title': 'Session timed out',
  'sessionTimeout.description': 'Your session has timed out due to inactivity.',
  'print.qrCode.header': 'Use este código QR para abrir la aplicación Pearson+ en su dispositivo móvil.',
  'print.qrCode.footer': 'Al escanear este código, se le redirigirá a la App Store o a Google Play. Después de haber instalado la aplicación, inicie sesión para descargar y acceder a su título.',
  'hero.nestest.title': 'Prepare for your teacher certification exam',
  'hero.nestest.subTitle': 'Pearson+ users can now access nationally aligned preparation courses and practice tests to prepare for their teacher certification exams.',
  'hero.channelAI.title': 'Need help? AI tutor has you covered.',
  'hero.channelAI.desc': 'Ask a question and get step-by-step homework help, study recommendations, and more — personalized help whenever you need it.',
  'hero.channelAI.action': 'Ask AI Tutor Now',
  new: 'New!'
};

export default es;
