import React from 'react';
import { createRoot } from 'react-dom/client';
import customProviderWithOrWithoutLD from './common/components/CustomProviderWithOrWithoutLD';

import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);

(async () => {
  const CustomProvider = await customProviderWithOrWithoutLD();
  root.render(
    <CustomProvider>
      <App />
    </CustomProvider>
  );
})();
